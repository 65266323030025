import { AxiosError, AxiosResponse } from 'axios';
import { Action } from 'redux';

import { IChecklist, Checklist, User } from '../models';

import http from './http';
import { ChecklistState } from '../reducers/checklist';
import { ThunkDispatch } from '../typings/thunk';
import { IErrorAction, ErrorActionTypes } from './error';

export enum ChecklistActionTypes {
  FETCH_ALL_SUCCESS = 'CHECKLIST_FETCH_ALL_SUCCESS',
  FETCH_SUCCESS = 'CHECKLIST_FETCH_SUCCESS',
  FETCH_VERSION_SUCCESS = 'CHECKLIST_FETCH_VERSION_SUCCESS',
  UPDATE_SUCCESS = 'CHECKLIST_UPDATE_SUCCESS',
  CREATE_SUCCESS = 'CHECKLIST_CREATE_SUCCESS',
  CELL_EDITED = 'CHECKLIST_CELL_EDITED',
  NAME_EDITED = 'CHECKLIST_NAME_EDITED',
  STATUS_EDITED = 'CHECKLIST_STATUS_EDITED',
  COLUMN_WIDTH_EDITED = 'CHECKLIST_COLUMN_WIDTH_EDITED',
  DELETE_SUCCESS = 'CHECKLIST_DELETE_SUCCESS',
  ROW_ADDED = 'CHECKLIST_ROW_ADDED',
  NO_OP = 'NOOP',
}

export interface IChecklistNoOpAction extends Action<string> {
  type: ChecklistActionTypes.NO_OP;
}

export interface IChecklistCellEditAction extends Action<string> {
  type: ChecklistActionTypes.CELL_EDITED;
  checklist: IChecklist;
  editData: { value: string; colIndex: number; rowIndex: number };
  user: User;
}

export interface IChecklistNameEditAction extends Action<string> {
  type: ChecklistActionTypes.NAME_EDITED;
  checklist: IChecklist;
  editData: { value: string };
}

export interface IChecklistStatusEditAction extends Action<string> {
  type: ChecklistActionTypes.STATUS_EDITED;
  checklist: IChecklist;
  editData: { value: string };
}

export interface IChecklistWidthEditAction extends Action<string> {
  type: ChecklistActionTypes.COLUMN_WIDTH_EDITED;
  checklist: IChecklist;
  editData: { value: number; colIndex: number };
}

export interface IChecklistFetchAllAction extends Action<string> {
  type: ChecklistActionTypes.FETCH_ALL_SUCCESS;
  checklists: IChecklist[];
}

export interface IChecklistFetchAction extends Action<string> {
  type: ChecklistActionTypes.FETCH_SUCCESS;
  checklist: IChecklist;
}

export interface IChecklistFetchVersionAction extends Action<string> {
  type: ChecklistActionTypes.FETCH_VERSION_SUCCESS;
  checklist: IChecklist;
}

export interface IChecklistCreateAction extends Action<string> {
  type: ChecklistActionTypes.CREATE_SUCCESS;
  checklist: IChecklist;
}

export interface IChecklistUpdateAction extends Action<string> {
  type: ChecklistActionTypes.UPDATE_SUCCESS;
  checklist: IChecklist;
}

export interface IChecklistDestroyAction extends Action<string> {
  type: ChecklistActionTypes.DELETE_SUCCESS;
  checklist: IChecklist;
}

export interface IChecklistRowAddAction extends Action<string> {
  type: ChecklistActionTypes.ROW_ADDED;
  checklist: IChecklist;
  editData: { rowIndex: number };
}

export type ChecklistActions = IChecklistNoOpAction
  | IChecklistCellEditAction
  | IChecklistNameEditAction
  | IChecklistStatusEditAction
  | IChecklistWidthEditAction
  | IChecklistCreateAction
  | IChecklistDestroyAction
  | IChecklistRowAddAction
  | IChecklistFetchAllAction
  | IChecklistFetchAction
  | IChecklistFetchVersionAction
  | IChecklistUpdateAction;

export function cellEdited(
  value: string, colIndex: number, rowIndex: number,
  checklist: IChecklist, user: User,
): IChecklistCellEditAction | IChecklistNoOpAction {
  if (checklist.rows[rowIndex][checklist.columns[colIndex].key] === value) return {type: ChecklistActionTypes.NO_OP};
  return {type: ChecklistActionTypes.CELL_EDITED, checklist, editData: {value, colIndex, rowIndex}, user};
}

export function nameEdited(value: string, checklist: IChecklist): IChecklistNameEditAction | IChecklistNoOpAction  {
  if (checklist.name === value) return {type: ChecklistActionTypes.NO_OP};
  return {type: ChecklistActionTypes.NAME_EDITED, checklist, editData: {value}};
}

export function statusEdited(value: string, checklist: IChecklist): IChecklistStatusEditAction | IChecklistNoOpAction {
  if (checklist.status === value) return {type: ChecklistActionTypes.NO_OP};
  return {type: ChecklistActionTypes.STATUS_EDITED, checklist, editData: {value}};
}

export function columnWidthEdited(value: number, colIndex: number, checklist: IChecklist): IChecklistWidthEditAction {
  return {type: ChecklistActionTypes.COLUMN_WIDTH_EDITED, checklist, editData: {value, colIndex}};
}

export function fetchAll() {
  return (dispatch: ThunkDispatch<ChecklistState, null, IChecklistFetchAllAction | IErrorAction>) =>
    http.get('/checklists')
      .then((res: AxiosResponse<IChecklist[]>) =>
        dispatch({type: ChecklistActionTypes.FETCH_ALL_SUCCESS, checklists: res.data}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}

export function fetch(id: number) {
  return (dispatch: ThunkDispatch<ChecklistState, null, IChecklistFetchAction | IErrorAction>) =>
    http.get(`/checklists/${id}`)
      .then((res: AxiosResponse<Checklist>) =>
        dispatch({type: ChecklistActionTypes.FETCH_SUCCESS, checklist: res.data}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}

export function fetchVersion(id: number) {
  return (dispatch: ThunkDispatch<ChecklistState, null, IChecklistFetchVersionAction | IErrorAction>) =>
    http.get(`/checklists/${id}`)
      .then((res: AxiosResponse<Checklist>) =>
        dispatch({type: ChecklistActionTypes.FETCH_VERSION_SUCCESS, checklist: res.data}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}

export function create(checklist: IChecklist) {
  return (dispatch: ThunkDispatch<ChecklistState, null, IChecklistCreateAction | IErrorAction>) =>
    http.post('/checklists', checklist)
      .then((res: AxiosResponse<Checklist>) => {
        dispatch({type: ChecklistActionTypes.CREATE_SUCCESS, checklist: res.data})
        return res.data;
      })
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}

export function update(checklist: IChecklist) {
  return (dispatch: ThunkDispatch<ChecklistState, null, IChecklistUpdateAction | IErrorAction>) =>
    http.put(`/checklists/${checklist.id}`, checklist)
      .then((res: AxiosResponse<IChecklist>) =>
        dispatch({type: ChecklistActionTypes.UPDATE_SUCCESS, checklist: res.data}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err: err.response.data.message}));
}

export function destroy(checklist: IChecklist) {
  return (dispatch: ThunkDispatch<ChecklistState, null, IChecklistDestroyAction | IErrorAction>) =>
    http.delete(`/checklists/${checklist.id}`)
      .then(() => dispatch({type: ChecklistActionTypes.DELETE_SUCCESS, checklist}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}

export function rowAdded(index: number, checklist: IChecklist): IChecklistRowAddAction {
  return {type: ChecklistActionTypes.ROW_ADDED, checklist, editData: {rowIndex: index} };
}
