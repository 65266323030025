import { Record } from 'immutable';

import { IColumn, ObjectType } from '../types/model_helpers';

export interface IChecklist {
  id?: number;
  templateId: number;
  name: string;
  description: string;
  comment?: string;
  isArchived: boolean;
  status: string;
  percentComplete?: number;
  columns: IColumn[];
  rows: ObjectType[];
  tags: string[];
  createdAt?: Date;
  updatedAt?: Date;
  updatedBy?: string;
  version: number;
  dirty?: boolean;
}

export class Checklist extends Record<IChecklist>({
  id: undefined,
  templateId: undefined,
  name: '',
  description: '',
  comment: '',
  isArchived: false,
  status: '',
  percentComplete: 0,
  columns: [],
  rows: [],
  tags: [],
  dirty: false,
  createdAt: null,
  updatedAt: null,
  updatedBy: null,
  version: 0,
}, 'Checklist') implements IChecklist {
  public id?: number;
  public templateId: number;
  public name: string;
  public description: string;
  public comment: string;
  public isArchived: boolean;
  public status: string;
  public percentComplete: number;
  public columns: IColumn[];
  public rows: ObjectType[];
  public tags: string[];
  public dirty?: boolean;
  public createdAt?: Date;
  public updatedAt?: Date;
  public updatedBy?: string;
  public version: number;
  constructor(props: IChecklist) {
    super(props);
  }
}
