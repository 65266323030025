import { UserActionTypes, UserActions } from '../actions/user';
import { User } from '../models/User';

export type UserState = User;
const initialState: User = null;

export default function userReducer(state: User = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.FETCH_SUCCESS:
      const { user } = action;
      return new User(user);
    case UserActionTypes.LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}
