export enum COLUMN_TYPES {
  TEXT,
  STATUS,
  NOT_EDITABLE,
  DROPDOWN,
}

export const STATUS_OPTIONS = Object.freeze([
  'Open', 'Closed', 'Pass', 'Fail',
  'Needs Review', 'Yes', 'No', 'Skip', 'N/A',
]);
export interface IColumn {
  key: string;
  name: string;
  resizable?: boolean;
  editable?: boolean;
  width?: number;
  values?: string[];
  type?: COLUMN_TYPES;
}

export interface IChecklistEditData {
  value?: string;
  values?: string[];
  colIndex: number;
  rowIndex: number;
 }

export interface ITemplateEditData extends IChecklistEditData {
  type: COLUMN_TYPES;
 }

export type ObjectType = {[key: string]: any};