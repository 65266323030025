import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alignment, Button, Icon, Navbar, NavbarGroup, NavbarHeading } from '@blueprintjs/core';

import { logout } from '../../actions/user';

interface IProps {
  logout?: () => Promise<any>;
  navbarRight: React.Component;
  navbarCenter: React.Component;
  navbarLeft: React.Component;
  showTitle: boolean;
  isCheckup: boolean;
}

export class CheckupNavbar extends React.Component<IProps> {
  static defaultProps = {
    showTitle: true,
    isCheckup: true,
  };

  constructor(props: IProps) {
    super(props);
  }

  render(): JSX.Element {
    const {
      showTitle,
      logout,
      navbarLeft,
      navbarCenter,
      navbarRight,
      isCheckup,
    } = this.props;

    return (
      <Navbar fixedToTop className={isCheckup ? 'checkup-nav' : 'trainup-nav'}>
        <div className="row center-sm">
          <div className="navbar-col">
            <NavbarGroup align={Alignment.LEFT}>
              <NavbarHeading>
                <Link to={ isCheckup ? '/' : '/training' } className="checkupHome" title="Home">
                  <Icon icon={ isCheckup ? 'home' : 'train' } title="Home"
                    iconSize={Icon.SIZE_LARGE} className={ showTitle ? 'mrs' : ''} />
                  { showTitle && <span>{ isCheckup ? 'Checkup' : 'TrainUp' }</span> }
                </Link>
              </NavbarHeading>
              {navbarLeft}
            </NavbarGroup>
            <NavbarGroup align={Alignment.CENTER}>
              {navbarCenter}
            </NavbarGroup>
            <NavbarGroup align={Alignment.RIGHT}>
              {navbarRight}
              <Button
                minimal={true}
                title="Log out"
                icon={<Icon icon="log-out" title="Log out" />}
                onClick={logout}
                className="logout"
                text="Logout" />
            </NavbarGroup>
          </div>
        </div>
      </Navbar>
    );
  }

}

export default connect(null, {
  logout,
})(CheckupNavbar);
