import { Action } from 'redux';

import { AxiosResponse, AxiosError } from 'axios';

import { IWhatsNew } from '../models';
import { ThunkDispatch } from '../typings/thunk';
import { WhatsNewState } from '../reducers/whatsNew';
import http from './http';
import { IErrorAction, ErrorActionTypes } from './error';

export enum WhatsNewActionTypes {
  FETCH_SUCCESS = 'WHATS_NEW_FETCH_SUCCESS',
  CREATE_SUCCESS = 'WHATS_NEW_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'WHATS_NEW_UPDATE_SUCCESS',
}

export interface IWhatsNewFetchAction extends Action<string> {
  type: WhatsNewActionTypes.FETCH_SUCCESS;
  whatsNew: IWhatsNew;
}

export interface IWhatsNewCreateAction extends Action<string> {
  type: WhatsNewActionTypes.CREATE_SUCCESS;
  whatsNew: IWhatsNew;
}

export interface IWhatsNewUpdateAction extends Action<string> {
  type: WhatsNewActionTypes.UPDATE_SUCCESS;
  whatsNew: IWhatsNew;
}

export type WhatsNewActions = IWhatsNewFetchAction
  | IWhatsNewCreateAction
  | IWhatsNewUpdateAction;

export function fetch() {
  return (dispatch: ThunkDispatch<WhatsNewState, null, IWhatsNewFetchAction | IErrorAction>) =>
    http.get('/whatsnew')
      .then((res: AxiosResponse<IWhatsNew>) => {
        dispatch({type: WhatsNewActionTypes.FETCH_SUCCESS, whatsNew: res.data})
        return res.data;
      })
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}

export function create(whatsNew: IWhatsNew) {
  return (dispatch: ThunkDispatch<WhatsNewState, null, IWhatsNewCreateAction>) =>
    http.post('/whatsnew', whatsNew)
      .then((res: AxiosResponse<IWhatsNew>) => {
        dispatch({type: WhatsNewActionTypes.CREATE_SUCCESS, whatsNew: res.data});
        return res.data;
      });
}

export function update(whatsNew: IWhatsNew) {
  return (dispatch: ThunkDispatch<WhatsNewState, null, IWhatsNewUpdateAction | IErrorAction>) =>
    http.put('/whatsnew', whatsNew)
      .then((res: AxiosResponse<IWhatsNew>) => {
        dispatch({type: WhatsNewActionTypes.UPDATE_SUCCESS, whatsNew: res.data});
        return res.data;
      })
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}