import { Map } from 'immutable';

import { Checklist, IChecklist } from '../models/Checklist';
import { IColumn, ObjectType } from '../types/model_helpers';
import { ChecklistActionTypes, ChecklistActions } from '../actions/checklist';
import { filterRows } from '../utils/table_helpers';

export type ChecklistState = Map<number, Checklist>;
const initialState: ChecklistState = Map<number, Checklist>();

export function percentDone(rows: ObjectType[]): number {
  const openItems = filterRows(rows, {status:['open', '']}).length;

  const percentDone = Math.round(((rows.length - openItems) / (rows.length)) * 100);
  return percentDone;
}

export default function checklistReducer(
  state: Map<number, Checklist> = initialState,
  action: ChecklistActions,
): ChecklistState {
  switch (action.type) {
    case ChecklistActionTypes.FETCH_ALL_SUCCESS:
      const { checklists } = action;
      return Map(checklists.map((item: IChecklist) => [item.id,
        new Checklist({
          ...item,
          percentComplete: percentDone(item.rows),
        })]));
    case ChecklistActionTypes.FETCH_SUCCESS:
    case ChecklistActionTypes.UPDATE_SUCCESS:
    case ChecklistActionTypes.CREATE_SUCCESS: {
      const {checklist} = action;
      return state.set(
        checklist.id,
        new Checklist({
          ...checklist,
          dirty: false,
          percentComplete: percentDone(checklist.rows),
        }),
      );
    }
    case ChecklistActionTypes.FETCH_VERSION_SUCCESS: {
      const {checklist} = action;
      const oldChklst = state.get(checklist.id);

      // If no edits were being made, auto-update checklist
      if (oldChklst && !oldChklst.dirty && oldChklst.version !== checklist.version) {
        return state.set(checklist.id, new Checklist({
          ...checklist,
          dirty: false,
          percentComplete: percentDone(checklist.rows),
        }));
      }

      return state;
    }
    case ChecklistActionTypes.DELETE_SUCCESS: {
      const {checklist} = action;
      return state.remove(checklist.id);
    }
    case ChecklistActionTypes.CELL_EDITED: {
      const { checklist, editData: { value, colIndex, rowIndex }, user } = action;
      const { columns } = checklist;
      const rows = [...checklist.rows];
      rows[rowIndex][columns[colIndex].key] = value;
      rows[rowIndex]['updatedBy'] = user.profile.displayName;

      return state.set(
        checklist.id,
        new Checklist(checklist)
          .set('rows', rows)
          .set('dirty', true)
          .set('percentComplete', percentDone(checklist.rows)),
      );
    }
    case ChecklistActionTypes.NAME_EDITED: {
      const { checklist, editData: { value } } = action;
      return state.set(
        checklist.id,
        new Checklist(checklist)
          .set('name', value)
          .set('dirty', true),
      );
    }
    case ChecklistActionTypes.STATUS_EDITED: {
      const { checklist, editData: { value } } = action;
      return state.set(
        checklist.id,
        new Checklist(checklist)
          .set('status', value)
          .set('dirty', true)
          .set('percentComplete', percentDone(checklist.rows)),
      );
    }
    case ChecklistActionTypes.COLUMN_WIDTH_EDITED: {
      const { checklist, editData: { value, colIndex } } = action;
      const columns = [...checklist.columns];
      columns[colIndex].width = value;
      return state.set(
        checklist.id,
        new Checklist(checklist)
          .set('columns', columns)
          .set('dirty', true),
      );
    }
    case ChecklistActionTypes.ROW_ADDED: {
      const { checklist, editData: { rowIndex } } = action;
      const row = checklist.columns.reduce((newRow: object, col: IColumn) => ({...newRow, [col.key]: ''}), {});
      const rows = [ ...checklist.rows.slice(0, rowIndex), row, ...checklist.rows.slice(rowIndex)];
      return state.set(
        checklist.id,
        new Checklist(checklist)
          .set('rows', rows)
          .set('dirty', true),
      );
    }
    default:
      return state;
  }
}