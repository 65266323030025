import * as React from 'react';
import { Button, Callout, Intent, Dialog } from '@blueprintjs/core';

import { Theme } from '../models';
import { ITemplateCreateAction, TemplateActionTypes } from '../actions/template';
import { AppToaster } from '../utils/toaster';
import { IErrorAction } from '../actions/error';
import ErrorDialog from './shared/ErrorDialog';

export type uploadCSVFn =
  (file: File, name: string, isTraining: boolean) => Promise<ITemplateCreateAction | IErrorAction>;

interface IProps {
  toggle: () => void;
  toggled: boolean;
  uploadCSV: uploadCSVFn;
  theme: Theme;
  isCheckup: boolean;
}

interface IState {
  selectedID: string;
  name: string;
  files: any;
  error: Error;
}

export class UploadTemplateDialog extends React.Component<IProps, IState> {
  initialState: IState = {
    selectedID: undefined,
    name: '',
    files: [],
    error: null,
  };

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState;
  }

  public render(): JSX.Element {
    const {
      toggled,
      toggle,
      theme,
      isCheckup,
    } = this.props;
    const {
      files,
      name,
      error,
    } = this.state;
    const file = files[0];

    return (
      <Dialog icon="add" className={`${theme.isDark ? 'pt-dark' : 'pt-light'}`}
        isOpen={toggled} onClose={this.closeDialog}
        title={isCheckup ? 'Upload new checklist template' : 'Upload new training template'}>
        <div className="pt-dialog-body">
          { error &&
            <Callout intent={Intent.DANGER} className="mbm">
              An error occurred. Check to be sure the template is valid.
            </Callout>
          }
          <p className="pt-ui-text mbm">
            All templates are <code>.csv</code> files where the first row contains header information
            and the remaining rows contain pre-populated data.
          </p>
          <div className="pt-form-group">
            <label className="pt-file-input">
              <input name="file" type="file" onChange={this.onFileChange} accept=".csv" />
              <span className="pt-file-upload-input">{file ? file.name : 'Choose file...'}</span>
            </label>
          </div>
          <div className="pt-form-group">
            <label className="pt-label">Name</label>
            <div className="pt-input-group">
              <input className="pt-input"
                name="name"
                type="text"
                value={name}
                onChange={e => this.setState({name: e.target.value})} />
            </div>
          </div>
        </div>
        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button
              minimal={true}
              intent={Intent.DANGER}
              text="Cancel"
              onClick={toggle}
            />
            <Button
              minimal={true}
              intent={Intent.PRIMARY}
              onClick={this.handleSubmit}
              disabled={!file}
              text="Create"
            />
          </div>
        </div>
        <ErrorDialog />
      </Dialog>
    );
  }

  private closeDialog = (): void => {
    const { toggle } = this.props;
    this.setState(this.initialState);
    toggle();
  };

  private onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({files: e.target.files});
  }

  private handleSubmit = (_e: React.MouseEvent<HTMLElement>): void => {
    const { files, name } = this.state;
    const { uploadCSV, toggle, isCheckup } = this.props;

    const file = files[0];
    uploadCSV(file, name, !isCheckup)
      .then(action => {
        if (action.type === TemplateActionTypes.CREATE_SUCCESS) {
          toggle();
          this.setState(this.initialState);
          AppToaster.show({
            message: `Successfully uploaded template "${name}"`,
            intent: Intent.SUCCESS,
          });
        }
      })
      .catch(error => {
        this.setState({ error });
      });
  }
}
