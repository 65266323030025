import checklistReducer, { ChecklistState } from './checklist';
import userReducer, { UserState } from './user';
import errorReducer, { ErrorState } from './error';
import templateReducer, { TemplateState } from './template';
import themeReducer, { ThemeState } from './theme';
import favoriteReducer, { FavoriteState } from './favorite';
import whatsNewReducer, { WhatsNewState } from './whatsNew';

export interface RootState {
  checklists: ChecklistState;
  templates: TemplateState;
  user: UserState;
  error: ErrorState;
  theme: ThemeState;
  favorites: FavoriteState;
  whatsNew: WhatsNewState;
}

export default {
  templates: templateReducer,
  checklists: checklistReducer,
  user: userReducer,
  error: errorReducer,
  theme: themeReducer,
  favorites: favoriteReducer,
  whatsNew: whatsNewReducer,
};
