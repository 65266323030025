import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Prompt } from 'react-router-dom';
import * as moment from 'moment';
import { Location } from 'history';
import * as ColorHash from 'color-hash';


import { IRegion } from '@blueprintjs/table';
import { IconNames } from '@blueprintjs/icons';
import { Button, Intent, ProgressBar, Icon } from '@blueprintjs/core';


import { ItemList, ListType } from './shared/ItemList';
import EditableText from './shared/EditableText';

import { IChecklist, ITemplate, User, Theme } from '../models';
import * as themeActions from '../actions/theme';
import * as checklistActions from '../actions/checklist';
import { IChecklistFetchAction } from '../actions/checklist';
import * as templateActions from '../actions/template';
import { IErrorAction } from '../actions/error';

import CenterContainer from './shared/CenterContainer';
import DynamicSelectString from './shared/DynamicStringSelect';
import Navbar from './shared/Navbar';
import { statusIntent } from '../utils/intent_helpers';
import ConfirmActionDialog from './shared/ConfirmActionDialog';
import ErrorDialog from './shared/ErrorDialog';
import { filterRows, updateColumnFilters } from '../utils/table_helpers';
import CommentDialog from './CommentDialog';
import { RootState } from '../reducers';

type PathParamsType = {
  id: string;
};

interface IProps extends RouteComponentProps<PathParamsType> {
  id: number;
  user: User;
  checklist?: IChecklist;
  template?: ITemplate;
  theme: Theme;
  history: any;
  location: any;
  fetch: (id: number) => Promise<IChecklistFetchAction | IErrorAction>;
  fetchVersion: (id: number) => any;
  fetchTemplate: (id: number) => any;
  loadIsDarkAction: () => any;
  cellEdited(value: string, colIndex: number, rowIndex: number, checklist: IChecklist, user: User): any;
  nameEdited(value: string, checklist: IChecklist): any;
  statusEdited(value: string, checklist: IChecklist): any;
  columnWidthEdited(value: number, colIndex: number, checklist: IChecklist): any;
  update(checklist: IChecklist): any;
  create(checklist: IChecklist): any;
  destroy(checklist: IChecklist): any;
  rowAdded(index: number, checklist: IChecklist): any;
}

interface IState {
  loading: boolean;
  dirty: boolean;
  selection: IRegion[];
  toggledDelete: boolean;
  showSaveSuccess: boolean;
  err?: string;
  editingFilter: boolean;
  commentDialogToggled: boolean;
  pollingTimeout: any;
  isCheckup: boolean;
}

export class ChecklistPage extends React.Component<IProps, IState, never> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      dirty: false,
      selection: [],
      toggledDelete: false,
      showSaveSuccess: false,
      err: null,
      editingFilter: false,
      commentDialogToggled: false,
      pollingTimeout: null,
      isCheckup: true,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  public render(): JSX.Element {
    const {
      checklist, nameEdited, statusEdited,
      columnWidthEdited, history, location: {query},
      user, theme, rowAdded, cellEdited,
      template,
    } = this.props;
    const {
      loading,
      toggledDelete,
      showSaveSuccess,
      commentDialogToggled,
      isCheckup,
    } = this.state;

    const tagColorGenerator = new ColorHash();

    if ( loading )
      return <CenterContainer centered loading theme={theme.isDark}><h2>Loading...</h2></CenterContainer>;
    if ( ! checklist || !template )
      return <CenterContainer centered><h2>Not found!</h2></CenterContainer>;

    const { name, rows, columns, dirty, id } = checklist;

    // const filteredRows = columnFilters(rows, query);
    const filteredRows = filterRows(rows, query);

    return (
      <div className={`pt-app ${theme.isDark ? 'pt-dark' : 'pt-light'}`}>
        <Prompt
          when={dirty}
          message={(location: Location) =>
            location.pathname.startsWith(`/list/${id}`) ? true : // returning true will allow the transition
              'Your changes have not been saved. Do you want to leave this page?'
          }
        />
        <ConfirmActionDialog
          theme={theme}
          toggled={toggledDelete}
          onClose={this.toggleDelete}
          entity={checklist}
          action={this.deleteChecklist}
          message={'Are you sure you want to delete?'} />
        <ErrorDialog />
        <Navbar
          isCheckup={isCheckup}
          showTitle={false}
          navbarLeft={
            <React.Fragment>
              <div style={{ display: 'flex', flexFlow: 'column' }}>
                <h3 className="nav-title">
                  <EditableText value={name}
                    onConfirm={() => this.updateCheckList()}
                    onChange={(val: string) => nameEdited(val, checklist)} />
                </h3>
                <div style={{ display: 'flex', flexFlow: 'row' }}>
                  <ProgressBar
                    className="pg-bar"
                    value={checklist.percentComplete / 100}
                    intent={Intent.SUCCESS}
                    animate={false}
                    stripes={false}
                  />
                  <p className="percentDone">{checklist.percentComplete}%</p>
                </div>
              </div>
              <DynamicSelectString
                inputProps={{value: checklist.status}}
                items={['Open', 'Closed', 'Pass', 'Fail', 'On Hold', 'Abandoned']}
                isItemActive={(item: string) => item === checklist.status}
                onItemSelect={(item: string) => statusEdited(item, checklist)}>
                <Button
                  rightIcon={<Icon icon={IconNames.CARET_DOWN} title="Update Status" />}
                  title="Update Status"
                  text={checklist.status}
                  intent={statusIntent(checklist.status)}
                  className={this.statusColors(checklist.status)}
                />
              </DynamicSelectString>
              <div style={{ display: 'block', position: 'relative' }}>
                <Button
                  minimal={true}
                  className="mls"
                  icon={<Icon icon={IconNames.COMMENT} title="Add Comment" />}
                  style={{marginLeft: '0.7rem', textAlign: 'left'}}
                  onClick={this.toggleCommentDialog} />
                { checklist.comment &&
                  <Icon icon="symbol-circle" intent={Intent.WARNING}
                    title={null}
                    style={{ position: 'relative', marginLeft: '-15px' }} />
                }
              </div>
              <div style={{display: 'block', position: 'relative', marginLeft: '2em', marginRight: '2em'}}>
                {checklist.tags.map(tag => <span className="pt-tag click-filter tag-type mrs"
                  key={tag}
                  style={{backgroundColor: tagColorGenerator.hex(tag)}}>{tag}</span>)}
              </div>
            </React.Fragment>
          }
          navbarRight={
            <React.Fragment>
              <div className="last-edit-time">
                { (!dirty && showSaveSuccess) ? 'All changes saved' :
                  ('Last save was ' +
                    moment(checklist.updatedAt).calendar(null, {
                      sameDay: '[today at] h:mm A',
                      lastDay: '[yesterday at] h:mm A',
                      lastWeek: '[last] dddd',
                      sameElse: '[on] MM/DD/YYYY',
                    }))
                }
              </div>
              <Button
                minimal={true}
                icon={<Icon icon={IconNames.FLOPPY_DISK} title="Save Checklist" />}
                intent={dirty ? Intent.SUCCESS : Intent.NONE}
                disabled={!dirty}
                onClick={this.updateCheckList}
              />
              <Button
                minimal={true}
                icon={<Icon icon={IconNames.TRASH} title="Delete Checklist" />}
                intent={Intent.DANGER}
                className="mrs"
                onClick={this.toggleDelete}
              />
            </React.Fragment>
          }
        />
        {/* spacer under nav bar: should be $pt-navbar-height
        http://blueprintjs.com/docs/v2/#core/components/navbar.fixed-to-viewport-top
        */}

        <div style={{marginTop: '4.2rem', textAlign: 'center'}}>
          <CommentDialog
            theme={theme}
            toggled={commentDialogToggled}
            toggle={this.toggleCommentDialog}
            checklist={checklist} />

          <ItemList
            type={isCheckup ? ListType.CHECKLIST : ListType.TRAINING}
            onColumnWidthChanged={(size: number, colIndex: number) => columnWidthEdited(size, colIndex, checklist)}
            onRowAdded={(rowIndex: number) => rowAdded(rowIndex, checklist)}
            onCellEdited={(value: string, colIndex: number, rowIndex: number) =>
              cellEdited(value, colIndex, rowIndex, checklist, user)}
            columns={columns}
            rows={filteredRows}
            isDarkTheme={theme.isDark}
            onColumnSearch={(filter: string, value: string) => updateColumnFilters(query, history, filter, value)}
            queryParams={query}
            save={this.updateCheckList}
          />
        </div>
      </div>
    );
  }

  public componentWillReceiveProps(nextProps: IProps): void {
    const { id: oldId, fetch } = this.props;
    const { id: newId, template } = nextProps;

    if (oldId !== newId) {
      this.setState({loading: true}, () => {
        fetch(newId)
          .then(() => this.setState({loading: false}))
          .catch(() => this.setState({loading: false}));
      });
    }

    if (template) {
      this.setState({
        isCheckup: !template.isTraining,
      });
    }
  }

  public componentWillUnmount(): void {
    const { pollingTimeout } = this.state;
    if (pollingTimeout) clearTimeout(pollingTimeout);
  }

  public componentDidMount(): void {
    const {
      checklist,
      fetch,
      fetchTemplate,
      id,
      loadIsDarkAction,
    } = this.props;

    loadIsDarkAction();
    if (!checklist) {
      this.setState({loading: true}, () => {
        fetch(id)
          .then((data: checklistActions.IChecklistFetchAction) => {
            if (data.checklist) {
              document.title = data.checklist.name;
              return fetchTemplate(data.checklist.templateId)
                .then(() => {
                  this.setState({loading: false});
                });
            }
            return this.setState({loading: false});
          })
          .catch(() => this.setState({loading: false}));
      });
    }
    else
      document.title = checklist.name;


    document.addEventListener('keydown', this.handleKeyDown);
    this.poll();
  }

  public handleKeyDown = (event: KeyboardEvent): void => {
    const charCode = String.fromCharCode(event.which).toLowerCase();

    // Ctrl-S
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      if (this.props.checklist.dirty) this.updateCheckList();
      event.preventDefault();
    }
  };

  private statusColors = (status: string): string => {
    let bgColor;
    if (status === 'Pass') bgColor = 'PassYes';
    else if (status === 'Abandoned') bgColor = 'SkipNA';
    return bgColor + ' mlm';
  }

  private toggleCommentDialog = (): void => {
    this.setState({ commentDialogToggled: !this.state.commentDialogToggled })
  };

  private toggleDelete = (): void => {
    this.setState({toggledDelete: !this.state.toggledDelete})
  };

  private deleteChecklist = (checklist: IChecklist): any => {
    const { history, destroy } = this.props;
    return destroy(checklist).then(history.push('/'))
  }

  private updateCheckList = (): void => {
    const { update, checklist } = this.props;
    return update(checklist)
      .then(() => this.setState({showSaveSuccess: true}))
      .then(() => {
        setTimeout(() => {
          this.setState({showSaveSuccess: false});
        }, 5000);
      })
      .catch((err: string) => this.setState({err}));
  }

  private poll = (): void => {
    const { fetchVersion, id } = this.props;
    fetchVersion(id);
    this.setState({
      pollingTimeout: setTimeout(() => {
        this.poll();
      }, 5000),
    });
  };
}

function mapStateToProps(state: RootState, ownProps: IProps): object {
  const { checklists, user, theme, templates } = state;
  const {match: {params: {id}}} = ownProps;

  const checklist = checklists.get(Number(id));

  return {
    theme,
    id: Number(id),
    checklist,
    template: checklist ? templates.get(Number(checklist.templateId)) : null,
    user,
  };
}

export default withRouter(connect(mapStateToProps, {
  ...checklistActions,
  fetchTemplate: templateActions.fetch,
  loadIsDarkAction: themeActions.loadIsDark,
})(ChecklistPage));
