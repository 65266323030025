import { Action } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';

import http from './http';
import { IFavorite } from '../models/Favorite';
import { ThunkDispatch } from '../typings/thunk';
import { FavoriteState } from '../reducers/favorite';
import { IErrorAction, ErrorActionTypes } from './error';

export enum FavoriteActionTypes {
  FETCH_SUCCESS = 'FAVORITE_FETCH_SUCCESS',
  CREATE_SUCCESS = 'FAVORITE_CREATE_SUCCESS',
  DELETE_SUCCESS = 'FAVORITE_DELETE_SUCCESS'
}

export interface IFavoriteFetchAction extends Action<string> {
  type: FavoriteActionTypes.FETCH_SUCCESS;
  favorites: IFavorite[];
}

export interface IFavoriteCreateAction extends Action<string> {
  type: FavoriteActionTypes.CREATE_SUCCESS;
  favorite: IFavorite;
}

export interface IFavoriteDeleteAction extends Action<string> {
  type: FavoriteActionTypes.DELETE_SUCCESS;
  favorite: IFavorite;
}

export type FavoriteActions = IFavoriteFetchAction
  | IFavoriteCreateAction
  | IFavoriteDeleteAction;

export function fetch() {
  return (dispatch: ThunkDispatch<FavoriteState, null, IFavoriteFetchAction | IErrorAction>) =>
    http.get('/favorites')
      .then((res: AxiosResponse<IFavorite[]>) =>
        dispatch({type: FavoriteActionTypes.FETCH_SUCCESS, favorites: res.data}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}

export function create(favorite: IFavorite) {
  return (dispatch: ThunkDispatch<FavoriteState, null, IFavoriteCreateAction>) =>
    http.post('/favorites', favorite)
      .then((res: AxiosResponse<IFavorite>) => {
        dispatch({type: FavoriteActionTypes.CREATE_SUCCESS, favorite: res.data});
        return res.data;
      });
}

export function destroy(favorite: IFavorite) {
  return (dispatch: ThunkDispatch<FavoriteState, null, IFavoriteDeleteAction>) =>
    http.delete(`/favorites/${favorite.checklistId}`)
      .then(() => dispatch({type: FavoriteActionTypes.DELETE_SUCCESS, favorite}));
}