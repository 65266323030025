import * as React from 'react';

import { Dialog, Button, Intent, Checkbox, Card, Icon } from '@blueprintjs/core';

import { Theme, IUser } from '../models';
import { getWhatsNew, IChange, prevVersion, nextVersion } from '../whats_new_changes';

interface IProps {
  close: (enabled: boolean) => Promise<void>;
  toggled: boolean;
  user: IUser;
  theme: Theme;
}

interface IState {
  enabled: boolean;
  version: string;
}

export class WhatsNewDialog extends React.Component<IProps, IState> {
  initialState: IState = {
    enabled: true,
    version: VERSION, /* eslint-disable-line no-undef */
  };

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState;
  }

  public render(): JSX.Element {
    const { toggled, theme, user } = this.props;
    const { version, enabled } = this.state;

    const newThings = getWhatsNew(version);
    return (
      <Dialog icon="star" className={`${theme.isDark ? 'pt-dark' : 'pt-light'}`}
        isOpen={toggled} onClose={this.closeDialog}
        canOutsideClickClose={false} style={{ width: 'auto', maxWidth: '750px' }}
        title="What's New?">
        <div className="pt-dialog-body" style={{ marginBottom: 0 }}>
          <h3>Release {version}</h3>
          <div style={{ display: 'flex', marginBottom: '1rem', justifyContent: 'center' }}>
            {
              newThings
                .filter((change: IChange) => { // Filter out based on change groups if present
                  if (!change.groups) return true;
                  return (user.groups || []).some(group => change.groups.indexOf(group.toLowerCase()) >= 0);
                })
                .map((change: IChange, index: number) => (
                  <Card className="mhs" key={`whats-new-${index}`}
                    style={{ textAlign: 'center', flex: 1, maxWidth: '355px' }}>
                    <Icon icon={change.icon} iconSize={32} className="mbs" intent={Intent.PRIMARY} />
                    <h4>{change.title}</h4>
                    <p>{change.text}</p>
                  </Card>
                ))
            }
          </div>
          <Checkbox
            checked={enabled}
            onChange={this.handleEnabledChange}
            label="Show when new features are added" />
        </div>
        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button
              minimal={true}
              intent={Intent.NONE}
              onClick={this.goToPrevVersion}
              text="Previous"
              disabled={prevVersion(version) === null}
            />
            <Button
              minimal={true}
              intent={Intent.NONE}
              onClick={this.goToNextVersion}
              text="Next"
              disabled={nextVersion(version) === null}
            />
            <Button
              minimal={true}
              intent={Intent.PRIMARY}
              onClick={this.closeDialog}
              text="Close"
            />
          </div>
        </div>
      </Dialog>
    )
  }

  private handleEnabledChange = (e: React.FormEvent<HTMLInputElement>): void => {
    this.setState({ enabled: (e.target as any).checked })
  };

  private closeDialog = (): void => {
    const { close } = this.props;
    close(this.state.enabled)
      .then(() => {
        this.setState(this.initialState);
      });
  }

  private goToPrevVersion = (): void => {
    this.setState({ version: prevVersion(this.state.version) });
  }

  private goToNextVersion = (): void => {
    this.setState({ version: nextVersion(this.state.version )});
  }
}