import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import { Button, Icon, Intent, ProgressBar, Spinner, Tag, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as ColorHash from 'color-hash';
import * as moment from 'moment';

import { Action } from 'redux';

import { Checklist, IChecklist, Template, Theme, User, Favorite, IFavorite, WhatsNew, IWhatsNew } from '../models';
import * as checklistActions from '../actions/checklist';
import * as themeActions from '../actions/theme';
import * as favoriteActions from '../actions/favorite';
import * as whatsNewActions from '../actions/whatsNew';
import { fetchAll as fetchAllTemplates, ITemplateFetchAllAction } from '../actions/template';

import { statusIntent } from '../utils/intent_helpers';
import { matchesFilter, updateColumnFilters, updateSortOrder } from '../utils/table_helpers';
import CreateChecklistDialog from './CreateChecklistDialog';
import CenterContainer from './shared/CenterContainer';
import Navbar from './shared/Navbar';
import { AppToaster } from '../utils/toaster';
import { RootState } from '../reducers';
import { IErrorAction } from '../actions/error';
import { WhatsNewDialog } from './WhatsNewDialog';
import { ThunkDispatch } from '../typings/thunk';
import { getWhatsNew } from '../whats_new_changes';

interface StateProps {
  checklists?: Map<number, Checklist>;
  templates?: Map<number, Template>;
  favorites?: Map<number, Favorite>;
  theme: Theme;
  user: User;
  whatsNew: WhatsNew;
}

interface DispatchProps {
  fetchAll: () => Promise<checklistActions.IChecklistFetchAllAction | IErrorAction>;
  fetchAllTemplates: () => Promise<ITemplateFetchAllAction | IErrorAction>;
  create: (checklist: IChecklist) => Promise<Checklist | IErrorAction>;

  setIsDarkAction: (isDark: boolean) => any;
  loadIsDarkAction: () => any;

  fetchFavorites: () => Promise<favoriteActions.IFavoriteFetchAction | IErrorAction>;
  addFavorite: (favorite: IFavorite) => Promise<IFavorite>;
  removeFavorite: (favorite: IFavorite) => Promise<favoriteActions.IFavoriteDeleteAction>;

  fetchWhatsNew: () => Promise<IWhatsNew | IErrorAction>;
  addWhatsNew: (whatsNew: IWhatsNew) => Promise<IWhatsNew>;
  updateWhatsNew: (whatsNew: IWhatsNew) => Promise<IWhatsNew | IErrorAction>;
}

interface OwnProps {
  history: any;
  location: any;
}

type Props = StateProps & DispatchProps & OwnProps;

interface IState {
  loading: boolean;
  dialogToggled: boolean;
  uploadDialogToggled: boolean;
  selected: IChecklist;
  createChecklistToggled: boolean;
  checklistSubGroup: Map<number, Checklist>;
  templateSubGroup: Map<number, Template>;
  sort: {
    column: keyof Checklist;
    ascending: boolean;
    icon: 'chevron-up' | 'chevron-down';
  };
  isCheckup: boolean;
  whatsNewToggled: boolean;
}

export class IndexPage extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      dialogToggled: false,
      uploadDialogToggled: false,
      whatsNewToggled: false,
      selected: undefined,
      createChecklistToggled: false,
      checklistSubGroup: Map(),
      templateSubGroup: Map(),
      sort: {
        column: 'updatedAt',
        ascending: false,
        icon: 'chevron-down',
      },
      isCheckup: true,
    };
  }

  public render(): JSX.Element {
    const { history, location: {query}, theme, user, favorites } = this.props;
    const {
      loading,
      createChecklistToggled,
      checklistSubGroup,
      templateSubGroup,
      isCheckup,
      whatsNewToggled,
    } = this.state;

    const tagColorGenerator = new ColorHash();
    const filteredLists = checklistSubGroup.valueSeq()
      .filter(checklist => matchesFilter(checklist.toJS(), query));
    const hasLists = filteredLists.toArray().length > 0;

    const favoritedLists: Checklist[] = [];
    const nonFavoriteLists: Checklist[] = [];
    filteredLists.forEach(checklist => {
      if (favorites.has(checklist.id)) favoritedLists.push(checklist);
      else nonFavoriteLists.push(checklist);
    });

    const renderListRow = (item: Checklist): JSX.Element => (<tr key={item.id}>
      <td style={{maxWidth: '8rem', wordWrap: 'break-word'}}>
        <Link to={`/list/${item.id}`}>
          { item.name || `Checklist ${item.id}` }
        </Link>
      </td>
      <td><Tag
        className="click-filter"
        style={{background: this.statusColors(item.status)}}
        onClick={() => updateColumnFilters(query, history, 'status', item.status)}
        intent={statusIntent(item.status)}>{item.status}</Tag></td>
      <td style={{maxWidth: '10rem', wordWrap: 'break-word'}}>
        {item.comment}
        {!item.comment && <span style={{fontStyle: 'italic', opacity: 0.3}}>No comment</span>}
      </td>
      <td>{item.percentComplete}% <ProgressBar
        value={(item.percentComplete) / 100}
        intent={Intent.SUCCESS}
        animate={false}
        stripes={false}
      />
      </td>
      <td style={{maxWidth: '8rem', wordBreak: 'break-word'}}>
        {item.tags.map(tag => <span className="pt-tag click-filter tag-type"
          key={tag}
          onClick={() => updateColumnFilters(query, history, 'tags', tag)}
          style={{backgroundColor: tagColorGenerator.hex(tag)}}>{tag}</span>)}
      </td>
      <td className="click-filter"
        onClick={() => updateColumnFilters(query, history, 'updatedBy', item.updatedBy)}>
        {item.updatedBy}</td>
      <td>{this.timeSince(item.updatedAt)}</td>
      <td>{this.timeSince(item.createdAt)}</td>
      <td style={{ textAlign: 'center' }}>
        {
          (favorites && favorites.has(item.id)) ?
            <Button minimal={true} intent={Intent.WARNING}
              icon={<Icon icon="star" title="Remove favorite" />}
              onClick={() => this.removeFave(item)} /> :
            <Button minimal={true}
              icon={<Icon icon="star-empty" title="Add as favorite" />}
              onClick={() => this.addNewFave(item)} />
        }
      </td>
    </tr>)

    return (
      <div className={`pt-app ${theme.isDark ? 'pt-dark' : 'pt-light'}`}>
        <CreateChecklistDialog
          create={this.createChecklist}
          toggle={this.toggle}
          toggled={createChecklistToggled}
          theme={theme}
          templates={templateSubGroup} />
        <WhatsNewDialog
          user={user}
          close={this.closeWhatsNew}
          toggled={whatsNewToggled}
          theme={theme} />
        <Navbar
          isCheckup={isCheckup}
          navbarLeft={
            this.createToggleFriend()
          }
          navbarCenter={
            <div className="pt-navbar">
              <div className="pt-navbar-group pt-align-left">
                <Link
                  className={
                    'pt-button pt-minimal pt-large pt-active is-nav-link ' +
                    (isCheckup ? 'pt-icon-clipboard' : 'pt-icon-graph')
                  }
                  to={isCheckup ? '/lists/active' : '/training'}>
                  {isCheckup ? 'Checklists' : 'Training'}
                </Link>
                <Link
                  className="pt-button pt-minimal pt-large pt-icon-document is-nav-link"
                  to={isCheckup ? '/templates' : '/training/templates'}>
                  Templates
                </Link>
              </div>
            </div>
          }
          navbarRight={
            <React.Fragment>
              <Button
                icon={<Icon icon={IconNames.ADD} title="Create checklist" />}
                minimal={true}
                title="Create checklist"
                intent={Intent.PRIMARY} onClick={this.toggle}
                className="mrs" text="New" />
            </React.Fragment> } />
        {/* spacer under nav bar: should be $pt-navbar-height
        http://blueprintjs.com/docs/v2/#core/components/navbar.fixed-to-viewport-top
        */}
        <div style={{width: '100%', height: '3.25rem'}}/>
        <CenterContainer>
          { !loading && (<table className="pt-html-table full-width">
            <thead>
              <tr>
                <th>
                  <div className="toggle-sort" onClick={() => this.toggleSort('name')} style={{marginBottom: 5}}>
                    Name
                    {this.showToggleIcon('name')}
                  </div>
                  <input
                    name="name"
                    className="pt-input"
                    value={query.name || ''}
                    onChange={e => updateColumnFilters(query, history, 'name', e.target.value)} />
                </th>
                <th>
                  <div className="toggle-sort" onClick={() => this.toggleSort('status') } style={{marginBottom: 5}}>
                    Status
                    {this.showToggleIcon('status')}
                  </div>
                  <input
                    name="status"
                    className="pt-input"
                    style={{width: '5rem'}}
                    value={query.status || ''}
                    onChange={e => updateColumnFilters(query, history, 'status', e.target.value)} />
                </th>
                <th>
                  <div style={{marginBottom: 5}}>
                    Comment
                  </div>
                  <input
                    name="comment"
                    className="pt-input"
                    style={{width: '10rem'}}
                    value={query.comment || ''}
                    onChange={e => updateColumnFilters(query, history, 'comment', e.target.value)}
                  />
                </th>
                <th>
                  <div className="toggle-sort" onClick={() => this.toggleSort('percentComplete')}
                    style={{marginBottom: 5}}>
                    % Complete
                    {this.showToggleIcon('percentComplete')}
                  </div>
                  <input
                    name="percentComplete"
                    className="pt-input"
                    disabled
                    style={{width: '5.5rem'}}
                    value={query.percentComplete || ''}
                    onChange={e => updateColumnFilters(query, history, 'percentComplete', e.target.value) } />
                </th>
                <th>
                  <div className="toggle-sort" onClick={() => this.toggleSort('tags') } style={{marginBottom: 5}}>
                    Type
                    {this.showToggleIcon('tags')}
                  </div>
                  <input
                    name="tags"
                    className="pt-input"
                    style={{width: '9rem'}}
                    value={query.tags || ''}
                    onChange={e => updateColumnFilters(query, history, 'tags', e.target.value)} />
                </th>
                <th>
                  <div className="toggle-sort" onClick={() => this.toggleSort('updatedBy') } style={{marginBottom: 5}}>
                    Last Updated By
                    {this.showToggleIcon('updatedBy')}
                  </div>
                  <input
                    name="updatedBy"
                    className="pt-input"
                    style={{width:'7.5rem'}}
                    value={query.updatedBy || ''}
                    onChange={e => updateColumnFilters(query, history, 'updatedBy', e.target.value)} />
                </th>
                <th>
                  <div className="toggle-sort" onClick={() => this.toggleSort('updatedAt') } style={{marginBottom: 5}}>
                    Last Updated
                    {this.showToggleIcon('updatedAt')}
                  </div>
                  <input
                    disabled
                    name="updatedAt"
                    className="pt-input"
                    style={{width:'7rem'}}
                    value={query.updatedAt || ''}
                    onChange={e => updateColumnFilters(query, history, 'updatedAt', e.target.value)} />
                </th>
                <th>
                  <div className="toggle-sort" onClick={() => this.toggleSort('createdAt') } style={{marginBottom: 5}}>
                    Created
                    {this.showToggleIcon('createdAt')}
                  </div>
                  <input
                    disabled
                    name="createdAt"
                    className="pt-input"
                    style={{width:'7rem'}}
                    value={query.createdAt || ''}
                    onChange={e => updateColumnFilters(query, history, 'createdAt', e.target.value)} />
                </th>
                <th>
                  <div style={{fontWeight:'normal', marginBottom: 5}}>
                    {checklistSubGroup.filter(checklist => matchesFilter(checklist.toJS(), query))
                      .sort((first, second) => this.onSort(first, second, this.state.sort))
                      .count()}/{checklistSubGroup.count()} lists
                  </div>
                  <Button
                    title="Clear filters"
                    minimal={true}
                    icon={<Icon icon={IconNames.DELETE} title="Clear filters" />}
                    onClick={() => this.clearFilters()}
                    className="pt-button clear"
                    text="Clear"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {
                favoritedLists.length > 0 &&
                <tr>
                  <td className='row-header' colSpan={9}>Favorite Lists</td>
                </tr>
              }
              {
                favoritedLists.sort((first, second) => this.onSort(first, second, this.state.sort))
                  .map(item => renderListRow(item))
              }
              {
                favoritedLists.length > 0 && nonFavoriteLists.length > 0 &&
                <tr>
                  <td className='row-header' colSpan={9}>Other Lists</td>
                </tr>
              }
              {
                nonFavoriteLists.sort((first, second) => this.onSort(first, second, this.state.sort))
                  .map(item => renderListRow(item))
              }
            </tbody>
          </table> )}
          {
            !loading && !hasLists &&
            <div className="pt-non-ideal-state">
              <div className="pt-non-ideal-state-visual pt-non-ideal-state-icon">
                <span className="pt-icon pt-icon-folder-open"></span>
              </div>
              <h4 className="pt-non-ideal-state-title">
                There are no existing {isCheckup ? 'checklists' : 'training lists'}
              </h4>
              <div className="pt-non-ideal-state-description">
                Create a new {isCheckup ? 'checklist' : 'training list'} to begin
              </div>
            </div>
          }
          { loading && <CenterContainer centered loading theme={theme.isDark}>
            <div style={{ height: 25 }} /><Spinner large />
          </CenterContainer> }
        </CenterContainer>
        <footer>
          {VERSION /* eslint-disable-line */} &copy; {new Date().getFullYear()} VoltServer Inc.
        </footer>
        {
          user && user.groups.indexOf('ENGINEERING') >= 0 &&
          <div id="floating-training-link">
            <Tooltip content={isCheckup ? 'Training' : 'Checkup'} inheritDarkTheme={true}>
              <Link to={isCheckup ? '/training' : '/'} title={isCheckup ? 'TrainUp' : 'Checkup'}>
                <Button
                  icon={<Icon icon={isCheckup ? IconNames.GRAPH : IconNames.CLIPBOARD}
                    title={isCheckup ? 'Go to Training' : 'Go to Checkup'} />}
                  title={isCheckup ? 'Training' : 'Checkup'}
                  intent={Intent.SUCCESS} />
              </Link>
            </Tooltip>
          </div>
        }
      </div>
    );
  }

  public componentWillReceiveProps(nextProps: Props): void {
    const { location: {pathname}, checklists, templates } = nextProps;
    switch (pathname) {
      case '/training':
      case '/training/lists':
        this.setState({
          checklistSubGroup: checklists.filter(checklist => {
            const template = templates.get(checklist.templateId);
            return template ? template.isTraining : false;
          }),
          templateSubGroup: templates.filter(template => template.isTraining),
          isCheckup: false,
        });
        document.title = 'TrainUp – Training';
        break;
      default:
        this.setState({
          checklistSubGroup: checklists.filter(checklist => {
            if (!checklist.templateId) return true;
            const template = templates.get(checklist.templateId);
            return template ? !template.isTraining : false;
          }),
          templateSubGroup: templates.filter(template => !template.isTraining),
          isCheckup: true,
        });
        document.title = 'Checkup – Checklists';
        break;
    }
  }

  public componentDidMount(): void {
    const {
      fetchAll,
      fetchAllTemplates,
      location,
      loadIsDarkAction,
      fetchFavorites,
    } = this.props;
    const ascending = location.query.order || false;
    loadIsDarkAction();

    this.setState({sort: {
      column: location.query.sort || 'updatedAt',
      ascending: ascending,
      icon: ascending ? 'chevron-up' : 'chevron-down',
    }});

    this.setState({loading: true}, () => {
      (async () => await Promise.all([
        fetchAll(),
        fetchAllTemplates(),
        fetchFavorites(),
        this.fetchWhatsNewData(),
      ]))()
        .then(() => this.setState({loading: false}))
        .catch(() => this.setState({loading: false}));
    });
  }

  private fetchWhatsNewData = async (): Promise<void> => {
    const { fetchWhatsNew } = this.props;
    await fetchWhatsNew();

    if (getWhatsNew(VERSION).length === 0) // eslint-disable-line no-undef
      return;

    const { whatsNew } = this.props;
    if (whatsNew && !this.state.whatsNewToggled) {
      if (whatsNew.enabled) {
        const currVersion = moment(VERSION.substring(1), 'YYYY.MM.DD'); // eslint-disable-line no-undef
        const lastVersion = moment(whatsNew.lastVersion.substring(1), 'YYYY.MM.DD');
        if (lastVersion.isBefore(currVersion))
          this.setState({ whatsNewToggled: true });
      }
    }
    else if (whatsNew === null)
      this.setState({ whatsNewToggled: true });
  }

  private statusColors = (status: string): string => {
    let bgColor;
    if (status === 'Pass') bgColor = '#30c53a';
    else if (status === 'Abandoned') bgColor = '#959fa7';
    return bgColor;
  }

  private timeSince(happenedAt: Date): string {
    const today = moment();
    if (today.diff(happenedAt, 'hours') < 24) return moment(happenedAt).fromNow();
    else return moment(happenedAt).format('MM/DD/YY');
  }

  private clearFilters(): void {
    const search = new URLSearchParams(window.location.search);
    search.delete('name');
    search.delete('status');
    search.delete('tags');
    search.delete('updatedBy');
    this.props.history.replace(search);
  }

  private showToggleIcon(columnKey: keyof Checklist): JSX.Element {
    const {
      sort: {column, ascending},
    } = this.state;
    if(columnKey === column) {
      return <Icon icon={ ascending ? 'chevron-up' : 'chevron-down' }
        title={ ascending ? 'Sort descending' : 'Sort ascending' } />;
    }
    return null;
  }

  private toggleSort(columnKey: keyof Checklist): void {
    const { ascending, icon, column } = this.state.sort;
    const { history, location: {query}} = this.props;
    if (columnKey === column) {
      this.setState({sort: { column: columnKey, ascending: !ascending, icon: icon}});
      updateSortOrder(query, history, 'sort', columnKey, 'order', !ascending);
    }
    else {
      this.setState({sort: { column: columnKey, ascending: false, icon: icon}});
      updateSortOrder(query, history, 'sort', columnKey, 'order', ascending);
    }
  }

  private onSort(one: Checklist, two: Checklist, sort: IState['sort']): number {
    const { column, ascending } = sort;

    let first = one[column];
    let second = two[column];
    let order = 0;

    if(column === 'updatedAt' || column === 'createdAt') {
      first = first as Date;
      second = second as Date;
      if (ascending)
        return +new Date(first) - +new Date(second);
      else
        return +new Date(second) - +new Date(first);
    }
    else if(column === 'tags') {
      first = first as string[];
      second = second as string[];
      if(ascending)
        return (second[0] || '').toString().localeCompare((first[0] || ''));
      else
        return (first[0] || '').toString().localeCompare((second[0] || ''));

    }
    else if (typeof first === 'number' && typeof second === 'number')
      order = first - second;
    else if (typeof first === 'string' && typeof second === 'string')
      order = second.localeCompare(first);

    return ascending ? order : order * -1;
  }

  private createToggleFriend(): JSX.Element {
    const { setIsDarkAction, theme } = this.props;
    return (
      <div id="container">
        <div className="toggle"
          title={ theme.isDark ? 'Toggle light theme' : 'Toggle dark theme' }>
          <input type="checkbox"
            name="toggle"
            className="check-checkbox"
            id="mytoggle"
            checked={theme.isDark}
            onChange={() => setIsDarkAction(!theme.isDark)}
          />
          <label className="check-label" htmlFor="mytoggle">
            <span className="face">
              <span className="face-container">
                <span className="eye left"></span>
                <span className="eye right"></span>
                <span className="mouth"></span>
              </span>
            </span>
          </label>
        </div>
      </div>
    );
  }

  private toggle = (): void => { this.setState({createChecklistToggled: !this.state.createChecklistToggled}) };

  private createChecklist = async (newChecklist: IChecklist): Promise<void> => {
    const { create, history } = this.props;
    const checklist = await create(newChecklist) as Checklist;

    history.push(`/list/${checklist.id}`);
    this.toggle();
  }

  private addNewFave = async (checklist: Checklist, showToast: boolean = true): Promise<void> => {
    const { fetchFavorites, addFavorite } = this.props;
    await addFavorite({ checklistId: checklist.id });
    fetchFavorites();
    if (showToast) {
      AppToaster.show({
        message: `"${checklist.name}" added as a favorite!`,
        icon: 'star',
        intent: Intent.WARNING,
        action: {
          onClick: () => this.removeFave(checklist, false),
          text: 'Undo',
        },
      });
    }
  }

  private removeFave = async (checklist: Checklist, showToast: boolean = true): Promise<void> => {
    const { fetchFavorites, removeFavorite} = this.props;
    await removeFavorite({ checklistId: checklist.id });
    fetchFavorites();
    if (showToast) {
      AppToaster.show({
        message: `"${checklist.name}" favorite removed.`,
        icon: 'remove',
        intent: Intent.DANGER,
        action: {
          onClick: () => this.addNewFave(checklist, false),
          text: 'Undo',
        },
      });
    }
  }

  private closeWhatsNew = (enabled: boolean): Promise<void> => {
    const { whatsNew, addWhatsNew, updateWhatsNew } = this.props;

    return (whatsNew
      ? updateWhatsNew({ enabled, lastVersion: VERSION }) /* eslint-disable-line */
      : addWhatsNew({ enabled, lastVersion: VERSION })) /* eslint-disable-line */
      .then(() => {
        this.setState({ whatsNewToggled: false });
      });
  }

}

function mapStateToProps(state: RootState): StateProps {
  const { checklists, templates, theme, user, favorites, whatsNew } = state;
  return {
    whatsNew,
    theme,
    checklists,
    templates,
    user,
    favorites: favorites.reduce((map, fav) =>
      map.set(fav.checklistId, new Favorite(fav)), Map()),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<RootState, {}, Action>): DispatchProps {
  return {
    fetchAll: () => dispatch(checklistActions.fetchAll()),
    fetchAllTemplates: () => dispatch(fetchAllTemplates()),
    create: (checklist: IChecklist) => dispatch(checklistActions.create(checklist)),

    setIsDarkAction: (isDark: boolean) => dispatch(themeActions.setIsDark(isDark)),
    loadIsDarkAction: () => dispatch(themeActions.loadIsDark()),

    fetchFavorites: () => dispatch(favoriteActions.fetch()),
    addFavorite: (favorite: IFavorite) => dispatch(favoriteActions.create(favorite)),
    removeFavorite: (favorite: IFavorite) => dispatch(favoriteActions.destroy(favorite)),

    fetchWhatsNew: () => dispatch(whatsNewActions.fetch()),
    addWhatsNew: (whatsNew: IWhatsNew) => dispatch(whatsNewActions.create(whatsNew)),
    updateWhatsNew: (whatsNew: IWhatsNew) => dispatch(whatsNewActions.update(whatsNew)),
  };
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(IndexPage);
