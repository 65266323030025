import { WhatsNew } from '../models';
import { WhatsNewActions, WhatsNewActionTypes } from '../actions/whatsNew';

export type WhatsNewState = WhatsNew;
const initialState: WhatsNewState = null;

export default function whatsNewReducer(
  state: WhatsNewState = initialState,
  action: WhatsNewActions,
): WhatsNewState {
  switch(action.type) {
    case WhatsNewActionTypes.FETCH_SUCCESS:
    case WhatsNewActionTypes.UPDATE_SUCCESS:
    case WhatsNewActionTypes.CREATE_SUCCESS: {
      const { whatsNew } = action;
      return new WhatsNew(whatsNew);
    }
    default:
      return state;
  }
}