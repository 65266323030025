import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Intent, Dialog } from '@blueprintjs/core';

import * as checklistActions from '../actions/checklist';
import { IChecklist, Checklist, Theme } from '../models';
import { RootState } from '../reducers';

type PathParamsType = {
  id: string;
};

interface IProps extends RouteComponentProps<PathParamsType> {
  toggle: () => void;
  toggled: boolean;
  theme: Theme;
  checklist?: Checklist;
  update(checklist: IChecklist): any;
}

interface IState {
  comment: string;
  err?: string;
}

class CommentDialog extends React.Component<IProps, IState> {
  initialState: IState = {
    comment: '',
    err: null,
  };

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState;
  }

  public render(): JSX.Element {
    const {
      toggled,
      toggle,
      theme,
    } = this.props;
    const {
      comment,
    } = this.state;

    return (
      <Dialog isOpen={toggled} className={`${theme.isDark ? 'pt-dark' : 'pt-light'}`} onClose={toggle} title="Comment">
        <div className="pt-dialog-body">
          <div className="pt-form-group">
            <div className="pt-input-group">
              <textarea className="pt-input" value={comment}
                rows={3} onChange={(e) => this.setState({comment: e.target.value})}
              />
            </div>
          </div>
          <p id="charcount"></p>
          {this.charCount(comment)}
        </div>
        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button
              minimal={true}
              intent={Intent.DANGER}
              text="Cancel"
              onClick={toggle}
            />
            <Button
              minimal={true}
              disabled={comment.length > 140}
              intent={Intent.PRIMARY}
              onClick={this.updateSave}
              text="Save"
            />
          </div>
        </div>
      </Dialog>
    );
  }

  public componentDidMount(): void {
    const {
      checklist,
    } = this.props;
    if ( checklist )
      this.setState({comment: checklist.comment});
  }

  public componentWillReceiveProps(nextProps: IProps): void {
    const {
      checklist,
    } = nextProps;
    if ( checklist )
      this.setState({comment: checklist.comment});
  }

  private updateSave = (): void => {
    this.updateComment();
    this.props.toggle();
  }

  private updateComment = (): any =>  {
    const { update, checklist } = this.props;
    const { comment } = this.state;
    return update(checklist.set('comment', comment));
  }

  private charCount = (comment: string): JSX.Element => {
    const text = comment.length;
    let limiting;

    if (text > 100 && text <= 140)
      limiting = '#d9822b';
    else if (text > 140)
      limiting = '#db3737';
    else
      limiting = '';


    return (<p style={{color: limiting, float:'right'}}>{text}/140 characters</p>);
  }
}

function mapStateToProps(state: RootState, ownProps: IProps): object {
  const { checklists, user, theme } = state;
  const {match: {params: {id}}} = ownProps;

  return {
    theme,
    id: Number(id),
    checklist: checklists.get(Number(id)),
    user,
  };
}

export default withRouter(connect(mapStateToProps, {
  ...checklistActions,
})(CommentDialog));
