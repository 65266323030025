import * as React from 'react';
import { connect } from 'react-redux';

import Navbar from './shared/Navbar';
import * as themeActions from '../actions/theme';
import { Theme } from '../models/Theme';
import { RootState } from '../reducers';

interface IProps {
  theme: Theme;
}

export class NotFoundPage extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  public render(): JSX.Element {
    const { theme } = this.props;
    return (
      <div className={`pt-app ${theme.isDark ? 'pt-dark' : 'pt-light'}`}>
        <Navbar />
        {/* spacer under nav bar: should be $pt-navbar-height
        http://blueprintjs.com/docs/v2/#core/components/navbar.fixed-to-viewport-top
        */}
        <div style={{ marginTop: '25vh' }}>
          <div className="pt-non-ideal-state">
            <div className="pt-non-ideal-state-visual pt-non-ideal-state-icon">
              <span className="pt-icon pt-icon-flame"></span>
            </div>
            <h4 className="pt-non-ideal-state-title">Uh oh! Page not found.</h4>
            <div className="pt-non-ideal-state-description">
              No worries, you didn&apos;t start the fire. It was always burning since the worlds been turning.
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount(): void {
    document.title = 'Checkup – Page Not Found';
  }
}

function mapStateToProps(state: RootState, _ownProps: IProps): object {
  const { theme } = state;
  return { theme };
}

export default connect(mapStateToProps, {
  loadIsDarkAction: themeActions.loadIsDark,
})(NotFoundPage);