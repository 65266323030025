import { Action } from 'redux';
import { AxiosResponse } from 'axios';

import http from './http';
import { IUser } from '../models';
import { ThunkDispatch } from '../typings/thunk';
import { UserState } from '../reducers/user';

export enum UserActionTypes {
  FETCH_SUCCESS = 'USER_FETCH_SUCCESS',
  LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS',
}

export interface IUserFetchAction extends Action<string> {
  type: UserActionTypes.FETCH_SUCCESS;
  user: IUser;
}

export interface IUserLogoutAction extends Action<string> {
  type: UserActionTypes.LOGOUT_SUCCESS;
}

export type UserActions = IUserFetchAction | IUserLogoutAction;

export function fetch() {
  return (dispatch: ThunkDispatch<UserState, null, IUserFetchAction>) =>
    http.get('/auth/me')
      .then((res: AxiosResponse<IUser>) => dispatch({type: UserActionTypes.FETCH_SUCCESS, user: res.data}));
}

export function logout() {
  return (dispatch: ThunkDispatch<UserState, null, IUserLogoutAction>) =>
    http.get('/auth/logout')
      .then(() => dispatch({type: UserActionTypes.LOGOUT_SUCCESS}));
}