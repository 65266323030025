import { Action } from 'redux';
import { AxiosResponse, AxiosError } from 'axios';

import http from './http';
import { ITemplate, User } from '../models';
import { COLUMN_TYPES } from '../types/model_helpers';
import { IErrorAction, ErrorActionTypes } from './error';
import { ThunkDispatch } from '../typings/thunk';
import { TemplateState } from '../reducers/template';

export enum TemplateActionTypes {
  FETCH_ALL_SUCCESS = 'TEMPLATE_FETCH_ALL_SUCCESS',
  FETCH_SUCCESS = 'TEMPLATE_FETCH_SUCCESS',
  UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS',
  CREATE_SUCCESS = 'TEMPLATE_CREATE_SUCCESS',
  CELL_EDITED = 'TEMPLATE_CELL_EDITED',
  NAME_EDITED = 'TEMPLATE_NAME_EDITED',
  COLUMN_WIDTH_EDITED = 'TEMPLATE_COLUMN_WIDTH_EDITED',
  COLUMN_VALUES_EDITED = 'TEMPLATE_COLUMN_VALUES_EDITED',
  COLUMN_TYPE_EDITED = 'TEMPLATE_COLUMN_TYPE_EDITED',
  ROW_ADDED = 'TEMPLATE_ROW_ADDED',
  DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS',
  NO_OP = 'NOOP',
};

export interface ITemplateNoOpAction extends Action<string> {
  type: TemplateActionTypes.NO_OP;
}

export interface ITemplateCellEditAction extends Action<string> {
  type: TemplateActionTypes.CELL_EDITED;
  template: ITemplate;
  editData: { value: string; colIndex: number; rowIndex: number };
  user: User;
}

export interface ITemplateNameEditAction extends Action<string> {
  type: TemplateActionTypes.NAME_EDITED;
  template: ITemplate;
  editData: { value: string };
}

export interface ITemplateWidthEditAction extends Action<string> {
  type: TemplateActionTypes.COLUMN_WIDTH_EDITED;
  template: ITemplate;
  editData: { value: number; colIndex: number };
}

export interface ITemplateRowAddAction extends Action<string> {
  type: TemplateActionTypes.ROW_ADDED;
  template: ITemplate;
  editData: { rowIndex: number };
}

export interface ITemplateValueEditAction extends Action<string> {
  type: TemplateActionTypes.COLUMN_VALUES_EDITED;
  template: ITemplate;
  editData: { values: string[]; colIndex: number };
}

export interface ITemplateTypeEditAction extends Action<string> {
  type: TemplateActionTypes.COLUMN_TYPE_EDITED;
  template: ITemplate;
  editData: { type: COLUMN_TYPES; colIndex: number };
}

export interface ITemplateFetchAllAction extends Action<string> {
  type: TemplateActionTypes.FETCH_ALL_SUCCESS;
  templates: ITemplate[];
}

export interface ITemplateFetchAction extends Action<string> {
  type: TemplateActionTypes.FETCH_SUCCESS;
  template: ITemplate;
}

export interface ITemplateCreateAction extends Action<string> {
  type: TemplateActionTypes.CREATE_SUCCESS;
  template: ITemplate;
}

export interface ITemplateUpdateAction extends Action<string> {
  type: TemplateActionTypes.UPDATE_SUCCESS;
  template: ITemplate;
}

export interface ITemplateDestroyAction extends Action<string> {
  type: TemplateActionTypes.DELETE_SUCCESS;
  template: ITemplate;
}

export type TemplateActions = ITemplateNoOpAction
  | ITemplateCellEditAction
  | ITemplateCreateAction
  | ITemplateDestroyAction
  | ITemplateFetchAction
  | ITemplateFetchAllAction
  | ITemplateNameEditAction
  | ITemplateRowAddAction
  | ITemplateTypeEditAction
  | ITemplateUpdateAction
  | ITemplateValueEditAction
  | ITemplateWidthEditAction;

export function cellEdited(
  value: string, colIndex: number, rowIndex: number,
  template: ITemplate, user: User,
): ITemplateCellEditAction | ITemplateNoOpAction {
  if (template.rows[rowIndex][template.columns[colIndex].key] === value) return {type: TemplateActionTypes.NO_OP};
  return {type: TemplateActionTypes.CELL_EDITED, template, editData: {value, colIndex, rowIndex}, user};
}

export function nameEdited(value: string, template: ITemplate): ITemplateNameEditAction | ITemplateNoOpAction {
  if ( template.name === value ) return {type: TemplateActionTypes.NO_OP};
  return {type: TemplateActionTypes.NAME_EDITED, template, editData: {value}};
}

export function columnWidthEdited(value: number, colIndex: number, template: ITemplate): ITemplateWidthEditAction {
  return {type: TemplateActionTypes.COLUMN_WIDTH_EDITED, template, editData: {value, colIndex}};
}

export function rowAdded(index: number, template: ITemplate): ITemplateRowAddAction {
  return {type: TemplateActionTypes.ROW_ADDED, template, editData: {rowIndex: index} };
}

export function columnValuesEdited(values: string[], colIndex: number, template: ITemplate): ITemplateValueEditAction {
  return {type: TemplateActionTypes.COLUMN_VALUES_EDITED, template, editData: {values, colIndex}};
}

export function columnTypeEdited(type: COLUMN_TYPES, colIndex: number, template: ITemplate): ITemplateTypeEditAction {
  return {type: TemplateActionTypes.COLUMN_TYPE_EDITED, template, editData: {type, colIndex}};
}

export function fetchAll() {
  return (dispatch: ThunkDispatch<TemplateState, null, ITemplateFetchAllAction | IErrorAction>) =>
    http.get('/templates')
      .then((res: AxiosResponse<ITemplate[]>) =>
        dispatch({type: TemplateActionTypes.FETCH_ALL_SUCCESS, templates: res.data}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}

export function fetch(id: number) {
  return (dispatch: ThunkDispatch<TemplateState, null, ITemplateFetchAction | IErrorAction>) =>
    http.get(`/templates/${id}`)
      .then((res: AxiosResponse<ITemplate>) =>
        dispatch({type: TemplateActionTypes.FETCH_SUCCESS, template: res.data}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}

export function create(template: ITemplate) {
  return (dispatch: ThunkDispatch<TemplateState, null, ITemplateCreateAction | IErrorAction>) =>
    http.post('/templates', template)
      .then((res: AxiosResponse<ITemplate>) => {
        dispatch({type: TemplateActionTypes.CREATE_SUCCESS, template: res.data});
        return res.data;
      })
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err}));
}

export function update(template: ITemplate) {
  return (dispatch: ThunkDispatch<TemplateState, null, ITemplateUpdateAction | IErrorAction>) =>
    http.put(`/templates/${template.id}`, template)
      .then((res: AxiosResponse<ITemplate>) =>
        dispatch({type: TemplateActionTypes.UPDATE_SUCCESS, template: res.data}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err: err.response.data.message}));
}

export function destroy(template: ITemplate) {
  return (dispatch: ThunkDispatch<TemplateState, null, ITemplateDestroyAction | IErrorAction>) =>
    http.delete(`/templates/${template.id}`)
      .then(() => dispatch({type: TemplateActionTypes.DELETE_SUCCESS, template}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err: err.response.data.message}));
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function uploadCSV(file: File, name?: string, isTraining?: boolean) {
  const csvData = new FormData();
  csvData.append('csvFile', file);
  csvData.append('name', name);
  // FormData.append only accepts strings or blobs for values
  if (isTraining) csvData.append('isTraining', isTraining.toString());

  return (dispatch: ThunkDispatch<TemplateState, null, ITemplateCreateAction | IErrorAction>) =>
    http.post('/templates/csv', csvData)
      .then((res: AxiosResponse<ITemplate>) =>
        dispatch({type: TemplateActionTypes.CREATE_SUCCESS, template: res.data}))
      .catch((err: AxiosError) => dispatch({type: ErrorActionTypes.ERROR, err: err.response.data.message}));
}
