import { Record } from 'immutable';

export interface IFavorite {
  id?: number;
  userId?: string;
  checklistId: number;
}

export class Favorite extends Record<IFavorite>({
  id: undefined,
  userId: '',
  checklistId: undefined,
}, 'Favorite') implements IFavorite {
  public id?: number;
  public userId?: string;
  public checklistId: number;
  constructor(props: IFavorite) {
    super(props);
  }
}