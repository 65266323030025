import * as React from 'react';

export default function CenterContainer({children, centered, loading, theme}:
  {children: any; centered?: boolean; loading?: boolean; theme?: boolean}): JSX.Element {

  if(loading) {
    return (
      <div className={`row ${centered ? 'text-centered' : ''} pt-app ${theme ? 'pt-dark' : 'pt-light'}`}>
        <div className="centerContainer">
          { children }
        </div>
      </div>
    );
  }
  return (
    <div className={`row ${centered ? 'text-centered' : ''}`} style={{minHeight: '80vh'}}>
      <div className="centerContainer">
        { children }
      </div>
    </div>
  );
}
