import { Map } from 'immutable';

import { TemplateActionTypes, TemplateActions } from '../actions/template';
import { COLUMN_TYPES, IColumn } from '../types/model_helpers';
import { Template } from '../models/Template';

export type TemplateState = Map<number, Template>;
const initialState: TemplateState = Map<number, Template>();

export default function templateReducer(
  state: Map<number, Template> = initialState,
  action: TemplateActions,
): TemplateState {
  switch (action.type) {
    case TemplateActionTypes.FETCH_ALL_SUCCESS:
      const { templates } = action;
      return Map(templates.map((item: Template) => [item.id, new Template(item)]));
    case TemplateActionTypes.FETCH_SUCCESS:
    case TemplateActionTypes.UPDATE_SUCCESS:
    case TemplateActionTypes.CREATE_SUCCESS: {
      const {template} = action;
      return state.set(template.id, new Template(template).set('dirty', false));
    }
    case TemplateActionTypes.DELETE_SUCCESS: {
      const {template} = action;
      return state.remove(template.id);
    }
    case TemplateActionTypes.CELL_EDITED: {
      const { template, editData: { value, colIndex, rowIndex }, user } = action;
      const { columns } = template;
      const rows = [...template.rows];
      rows[rowIndex][columns[colIndex].key] = value;
      rows[rowIndex]['updatedBy'] = user.profile.displayName;
      return state.set(
        template.id,
        new Template(template)
          .set('rows', rows)
          .set('dirty', true),
      );
    }
    case TemplateActionTypes.NAME_EDITED: {
      const { template, editData: { value } } = action;
      return state.set(
        template.id,
        new Template(template)
          .set('name', value)
          .set('dirty', true),
      );
    }
    case TemplateActionTypes.COLUMN_WIDTH_EDITED: {
      const { template, editData: { value, colIndex } } = action;
      const columns = [...template.columns];
      columns[colIndex].width = Number(value);
      return state.set(
        template.id,
        new Template(template)
          .set('columns', columns)
          .set('dirty', true),
      );
    }
    case TemplateActionTypes.COLUMN_VALUES_EDITED: {
      const { template, editData: { values, colIndex } } = action;
      const columns = [...template.columns];
      columns[colIndex].values = values;
      columns[colIndex].editable = true;
      return state.set(
        template.id,
        new Template(template)
          .set('columns', columns)
          .set('dirty', true),
      );
    }
    case TemplateActionTypes.COLUMN_TYPE_EDITED: {
      const { template, editData: { type, colIndex } } = action;
      const columns = [...template.columns];
      let rows = [...template.rows];
      columns[colIndex].type = type;
      if (type === COLUMN_TYPES.STATUS)
        rows = rows.map((row: object) => ({...row, [columns[colIndex].key]: 'Open'}));
      return state.set(
        template.id,
        new Template(template)
          .set('columns', columns)
          .set('rows', rows)
          .set('dirty', true),
      );
    }
    case TemplateActionTypes.ROW_ADDED: {
      const { template, editData: { rowIndex } } = action;
      const row = template.columns.reduce((newRow: object, col: IColumn) => ({...newRow, [col.key]: ''}), {});
      const rows = [ ...template.rows.slice(0, rowIndex), row, ...template.rows.slice(rowIndex)];
      return state.set(
        template.id,
        new Template(template)
          .set('rows', rows)
          .set('dirty', true),
      );
    }
    default:
      return state;
  }
}
