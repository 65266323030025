import { IconName } from '@blueprintjs/core';

export interface IChange {
  icon: IconName;
  title: string;
  text: string;
  groups?: string[]; // all lowercase
}

const changes: {[key: string]: IChange[]} = {
  // NEW VERSIONS GO HERE
  'v2020.5.26': [
    {
      icon: 'link',
      title: 'Link to Icon',
      text: 'Links in Trainup checklists now appear as icons to save space.',
    },
    {
      icon: 'filter',
      title: 'Filter Fixed',
      text: 'Filter fields are no longer hidden when a filtered query returns no results.',
    }
  ],
  'v2020.04.27': [
    {
      icon: 'help',
      title: 'Row Help Text',
      text: 'All templates now have a "Help Text" column to add additional information for associated row.',
    },
    {
      icon: 'star',
      title: 'What\'s New Alert',
      text: 'This popup will appear to give highlights of new features that were added.',
    },
    {
      icon: 'envelope',
      title: 'Weekly Email',
      text: 'Weekly updates detailing which checklists have gone unmodified for over 30 days.',
      groups: ['checkup admins'],
    },
  ],
  'v2019.12.12': [
    {
      icon: 'info-sign',
      title: 'Feedback Messages',
      text: 'Show message toasts are displayed when actions are completed successfully.',
    },
  ],
  'v2019.10.21': [
    {
      icon: 'star',
      title: 'Checklist Favorites',
      text: 'Frequently used checklists can be favorited / starred for quick access.',
    },
    {
      icon: 'error',
      title: 'Needs Review',
      text: 'Status fields now have a "Needs Review" option.',
    },
  ],
  'v2019.09.11': [
    {
      icon: 'graph',
      title: 'TrainUp',
      text: 'Training lists and templates can be created for constant knowledge improvements.',
    },
    {
      icon: 'link',
      title: 'Clickable URLs',
      text: 'URL links in text fields have actual links when hovering over the field.',
    },
  ],
  // OLDER VERSIONS HERE
};

export function getWhatsNew(version: string): IChange[] {
  return changes[version] || []
}

export function prevVersion(version: string): string | null {
  const versions = Object.keys(changes);
  const currIdx = versions.indexOf(version);

  if (currIdx === versions.length - 1)
    return null;
  return versions[currIdx + 1];
}

export function nextVersion(version: string): string | null {
  const versions = Object.keys(changes);
  const currIdx = versions.indexOf(version);

  if (currIdx === 0)
    return null;
  return versions[currIdx - 1];
}