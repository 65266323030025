import * as React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

import Root from './components/Root';
import store, { history } from './store';
import * as userActions from './actions/user';

const rootEl = document.getElementById('root');

userActions.fetch()(store.dispatch);

render(
  <AppContainer>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Root/>
      </ConnectedRouter>
    </Provider>
  </AppContainer>,
  rootEl,
);

// Hot Module Replacement API
declare let module: { hot: any };

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <NewRoot/>
          </ConnectedRouter>
        </Provider>
      </AppContainer>,
      rootEl,
    );
  });
}