import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Button, Intent, Dialog } from '@blueprintjs/core';

import { Theme } from '../../models/Theme';

interface IProps {
    message: string;
    onCancel: () => void;
    onConfirm: () => void;
    theme: Theme;
}

function getTheme(): string {
  if(window.localStorage.getItem('isDark') === `${true}`)
    return 'pt-dark';
  else if(window.localStorage.getItem('isDark') === `${false}`)
    return 'pt-light';
  return null;
}

/**
 * Used as custom prompt with React Router v4.
 * @param message
 * @param callback
 */
const ConfirmNavigationDialog = (message: string, callback: Function): void => {

  const modal = document.createElement('div')
  document.body.appendChild(modal)

  const withCleanup = (answer: boolean): void => {
    ReactDOM.unmountComponentAtNode(modal)
    document.body.removeChild(modal)
    callback(answer)
  }

  const themeColor = getTheme();

  ReactDOM.render(
    <Dialog
      className={themeColor}
      title="Confirm Navigation"
      onClose={() => withCleanup(false)}
      isOpen={true}>
      <div className="pt-dialog-body">
        {message}
      </div>
      <div className="pt-dialog-footer">
        <div className="pt-dialog-footer-actions">
          <Button
            minimal={true}
            intent={Intent.DANGER}
            onClick={() => withCleanup(true)}
            text="Yes, discard unsaved changes"
          />
          <Button
            minimal={true}
            intent={Intent.PRIMARY}
            text="No, stay on this page"
            onClick={() => withCleanup(false)}
          />
        </div>
      </div>
    </Dialog>,
    modal,
  )
}

export default ConfirmNavigationDialog;
