import * as React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Location } from 'history';

import { RootState } from '../reducers';
import { IUser } from '../models';

export const LoginPage = ({user, location}:
    {user: IUser; location: Location}): JSX.Element => {
  const { from } = location.state || { from: { pathname: '/' } };
  if (user)
    return <Redirect to={ from } />;

  document.title = 'Checkup – Login';

  return (
    <section className="login-page row center-xs middle-xs">
      <div className="hero-body">
        <div className="clipboard">
          <div className="clip">
            <div className="bottom" />
          </div>
          <div className="paper">
            <div className="column is-half is-offset-one-quarter">
              <img src="/img/logo.svg"
                className="logo voltserv-logo"
                title="VoltServer" alt="VoltServer Logo" />
              <div className="flex-wrapper">
                <div>
                  <h2 className="app-title">CHECKUP</h2>
                  <h4 className="app-subtitle">A Checklist App</h4>
                  <a href="/auth/openid" className="login-link">
                    <img src="/img/ms-symbollockup_signin_light.svg" className="logo" alt="Login" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default connect((state: RootState) => ({user: state.user}))(LoginPage)
