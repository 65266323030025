import * as React from 'react';
import { Button, Intent, Dialog } from '@blueprintjs/core';
import { connect } from 'react-redux';

import * as sharedActions from '../../actions/error';
import { RootState } from '../../reducers';

interface IProps {
    clearError: () => Promise<void>;
    error: string;
}

export const ErrorDialog: React.SFC<IProps> = ({error, clearError}) => (
  <Dialog
    title="A problem occurred"
    icon="error"
    isOpen={!!error}
    onClose={clearError}>
    <div className="pt-dialog-body">
      {error}
    </div>
    <div className="pt-dialog-footer">
      <div className="pt-dialog-footer-actions">
        <Button
          minimal={true}
          intent={Intent.PRIMARY}
          text="OK"
          onClick={clearError}
        />
      </div>
    </div>
  </Dialog>
)

function mapStateToProps(state: RootState): object {
  const { error } = state;
  return {
    error,
  };
}

export default connect(mapStateToProps, {
  clearError: sharedActions.clearError,
})(ErrorDialog);
