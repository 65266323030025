import * as React from 'react';
import { Button, Intent, Dialog } from '@blueprintjs/core';

import { Theme } from '../../models/Theme';

interface IProps {
    toggled: boolean;
    onClose: () => void;
    onNo: () => void;
    noText?: string;
    noIntent?: Intent;
    onYes: () => void;
    yesText?: string;
    yesIntent?: Intent;
    message: React.ReactNode;
    theme: Theme;
    hasCancel?: boolean;
}

const YesNoActionDialog: React.SFC<IProps> = ({
  toggled, onClose, onNo, onYes, yesText,
  noText, message, theme, hasCancel,
  noIntent, yesIntent,
}) => (
  <Dialog
    className={`${theme.isDark ? 'pt-dark' : 'pt-light'}`}
    title="Please Choose"
    isOpen={toggled}
    onClose={onClose}>
    <div className="pt-dialog-body">
      {message}
    </div>
    <div className="pt-dialog-footer">
      <div className="pt-dialog-footer-actions">
        {
          hasCancel &&
                   <Button
                     minimal={true}
                     intent={Intent.NONE}
                     onClick={onClose}
                     text='Cancel'
                   />
        }
        <Button
          minimal={true}
          intent={noIntent || Intent.DANGER}
          text={noText || 'No'}
          onClick={onNo}
        />
        <Button
          minimal={true}
          intent={yesIntent || Intent.PRIMARY}
          onClick={onYes}
          text={yesText || 'Yes'}
        />
      </div>
    </div>
  </Dialog>
)

export default YesNoActionDialog;
