import * as React from 'react';
import {
  Route,
  Switch,
} from 'react-router';

import ChecklistPage from './ChecklistPage';
import IndexPage from './IndexPage';
import LoginPage from './LoginPage';
import NotFoundPage from './NotFoundPage';
import PrivateRoute from './shared/PrivateRoute';
import TemplatePage from './TemplatePage';
import TemplatesPage from './TemplatesPage';

import './App.scss';
import './DarkTheme.scss';
import './ToggleTheme.scss';

export default function Root(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <PrivateRoute exact path="/" component={IndexPage}/>
      <PrivateRoute exact path="/training" component={IndexPage}/>
      <PrivateRoute exact path="/lists" component={IndexPage}/>
      <PrivateRoute exact path="/lists/active" component={IndexPage}/>
      <PrivateRoute exact path="/training/lists" component={IndexPage}/>
      <PrivateRoute exact path="/templates" component={TemplatesPage}/>
      <PrivateRoute exact path="/templates/active" component={TemplatesPage}/>
      <PrivateRoute exact path="/training/templates" component={TemplatesPage}/>
      <PrivateRoute path="/list/:id" component={ChecklistPage}/>
      <PrivateRoute exact path="/templates" component={TemplatesPage}/>
      <PrivateRoute path="/templates/:id" component={TemplatePage}/>
      <PrivateRoute component={NotFoundPage}/>
    </Switch>
  );
}
