import createHistory from 'history/createBrowserHistory';
import {
  routerMiddleware,
  routerReducer,
} from 'react-router-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';

import qhistory from 'qhistory';
import { stringify, parse } from 'qs';

import reducers from './reducers'; // Or wherever you keep your reducers
import ConfirmNavigationDialog from './components/shared/ConfirmNavigationDialog';

// Create a history of your choosing (we're using a browser history in this case)
const history = qhistory(
  createHistory({
    getUserConfirmation: ConfirmNavigationDialog,
  }),
  stringify,
  parse,
);

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer,
  }),
  composeEnhancers(applyMiddleware(
    routerMiddleware(history),
    thunk,
  )),
);

export default store;
export {
  history,
};
