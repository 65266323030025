import { Action } from 'redux';

import { ThunkDispatch } from '../typings/thunk';
import { ThemeState } from '../reducers/theme';

export enum ThemeActionTypes {
  LOAD_DARK = 'THEME_LOAD_DARK',
  SET_DARK = 'THEME_SET_DARK',
}

export interface IThemeLoadAction extends Action<string> {
  type: ThemeActionTypes.LOAD_DARK;
  isDark: boolean;
}

export interface IThemeSetAction extends Action<string> {
  type: ThemeActionTypes.SET_DARK;
  isDark: boolean;
}

export type ThemeActions = IThemeLoadAction | IThemeSetAction;

export function loadIsDark() {
  return (dispatch: ThunkDispatch<ThemeState, null, IThemeLoadAction>) => {
    const isDark = window.localStorage.getItem('isDark') === 'true';
    return dispatch({type: ThemeActionTypes.LOAD_DARK, isDark});
  };
}

export function setIsDark(isDark: boolean) {
  return (dispatch: ThunkDispatch<ThemeState, null, IThemeSetAction>) => {
    window.localStorage.setItem('isDark', String(isDark))
    return dispatch({type: ThemeActionTypes.SET_DARK, isDark});
  };
}
