import * as React from 'react';
import { Map } from 'immutable';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Intent, Dialog } from '@blueprintjs/core';

import { Theme, IChecklist, Template } from '../models';
import { STATUS_OPTIONS } from '../types/model_helpers';

interface IProps extends RouteComponentProps {
  templates?: Map<number, Template>;
  create: (checklist: IChecklist) => Promise<void>;
  toggle: () => void;
  toggled: boolean;
  theme: Theme;
}

interface IState {
  selectedID: string;
  name: string;
}

class CreateChecklistDialog extends React.Component<IProps, IState> {
  initialState: IState = {
    selectedID: undefined,
    name: '',
  };

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState;
  }

  public render(): JSX.Element {
    const { templates, toggled, toggle, theme } = this.props;
    const { selectedID, name } = this.state;

    return (
      <Dialog isOpen={toggled} className={`${theme.isDark ? 'pt-dark' : 'pt-light'}`} onClose={toggle} title="Create new checklist">
        <div className="pt-dialog-body">
          <div className="pt-form-group">
            <label className="pt-label">Checklist Template
              <div className="pt-select">
                <select value={selectedID} onChange={e => this.setState({selectedID: e.target.value})}>
                  <option value=''>Choose a template</option>
                  {templates.valueSeq()
                    .sort((f1, f2) => f1.name.localeCompare(f2.name))
                    .map((template) => <option value={template.id} key={template.id}>{template.name}</option>)}
                </select>
              </div>
            </label>
          </div>
          <div className="pt-form-group">
            <label className="pt-label">Name</label>
            <div className="pt-input-group">
              <input className="pt-input" type="text" value={name}
                onChange={(e) => this.setState({name: e.target.value})}
                onKeyUp={this.onKeyUp} />
            </div>
          </div>
        </div>
        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button
              minimal={true}
              intent={Intent.DANGER}
              text="Cancel"
              onClick={toggle}
            />
            <Button
              minimal={true}
              intent={Intent.PRIMARY}
              onClick={this.createAction}
              disabled={!selectedID}
              text="Create"
            />
          </div>
        </div>
      </Dialog>
    );
  }

  private createAction = async (): Promise<void> => {
    const { templates, create } = this.props;
    const { selectedID, name } = this.state;
    const parentTemplate = templates.get(Number.parseInt(selectedID));
    const newChecklist: IChecklist = {
      templateId: parentTemplate.id,
      name,
      description: 'the description',
      isArchived: false,
      status: STATUS_OPTIONS[0], // "Open"
      columns: parentTemplate.columns,
      rows: parentTemplate.rows,
      tags: [parentTemplate.name],
      version: 0,
    };
    await create(newChecklist);
    this.setState(this.initialState);
  }

  private onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === 13) { // CRLF
      this.createAction();
    }
  }
}

export default withRouter(CreateChecklistDialog);
