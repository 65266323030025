import * as React from 'react';

import { Icon, Menu, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemListRenderer, ItemRenderer, Select } from '@blueprintjs/select';


interface IProps {  // a bit confusing because blueprint also has an IProps
    isItemActive: (item: string) => boolean;
    handleAdd?: (value: string) => Promise<void>;
    canAdd?: boolean;
    inputProps: object;
    items: any;
    onItemSelect: (item: string) => Promise<void>;
}

interface IState {
    addingItem: boolean;
    newValue: string;
}

const StringSelect = Select.ofType<string>();

export default class DynamicStringSelect extends React.Component<IProps, IState> {
    private initialState: IState = {
      addingItem: false,
      newValue: '',
    };

    constructor(props: IProps) {
      super(props);
      this.state = this.initialState;
    }

    public render(): JSX.Element {
      const { inputProps, items, onItemSelect, children } = this.props;
      return (
        <StringSelect
          filterable={false}
          inputProps={{...inputProps}}
          itemRenderer={this.renderString.bind(this)}
          itemListRenderer={this.renderMenu.bind(this)}
          items={items}
          onItemSelect={onItemSelect}>
          {children}
        </StringSelect>
      );
    }

    public onBlur = (): void => { this.setState({addingItem: false, newValue: ''}) };

    private renderMenu: ItemListRenderer<string> = ({ items, itemsParentRef, renderItem }) => {
      const renderedItems = items.map(renderItem).filter(item => item != null);
      const { canAdd } = this.props;
      const { newValue, addingItem } = this.state;
      if (!canAdd) return (<Menu ulRef={itemsParentRef}>{renderedItems}</Menu>)
      return (
        <Menu ulRef={itemsParentRef}>
          {renderedItems}
          {addingItem ?
            <div className='pt-menu-item'>
              <input autoFocus={true} className='pt-input' type='text'
                value={newValue}
                onChange={(e) => this.setState({newValue: e.target.value})}
                onBlur={this.onBlur}
                onKeyUp={this.handleEnter} />
            </div> :
            <MenuItem
              icon={<Icon icon={IconNames.ADD} title="Add option" />}
              text={'Add option to column'}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                this.setState({addingItem: true});
                event.stopPropagation();
                return false;
              }}
            />
          }
        </Menu>
      )
    };

    private renderString: ItemRenderer<string> = (item, { handleClick }) => {
      const { isItemActive } = this.props;
      return (
        <MenuItem
          active={isItemActive(item)}
          key={item}
          onClick={handleClick}
          text={item}
        />
      )
    }

    private handleEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
      const { handleAdd } = this.props;
      const { newValue } = this.state;
      if (e.key === 'Enter') {
        handleAdd(newValue);
        this.setState(this.initialState);
        e.stopPropagation();
      }
    }
}
