import * as React from 'react';
import { Button, Intent, Dialog } from '@blueprintjs/core';

import { Theme } from '../../models/Theme';

interface IProps {
    toggled: boolean;
    onClose: () => void;
    entity: any;
    action: (val: any) => any;
    message: string;
    theme: Theme;
}

const ConfirmActionDialog: React.SFC<IProps> = ({toggled, onClose, entity, action, message, theme}) => {
  const confirmAction = (): any => action(entity);
  return (
    <Dialog
      className={`${theme.isDark ? 'pt-dark' : 'pt-light'}`}
      title="Please Confirm"
      isOpen={toggled}
      onClose={onClose}>
      <div className="pt-dialog-body">
        {message}
      </div>
      <div className="pt-dialog-footer">
        <div className="pt-dialog-footer-actions">
          <Button
            minimal={true}
            intent={Intent.DANGER}
            text="Cancel"
            onClick={onClose}
          />
          <Button
            minimal={true}
            intent={Intent.PRIMARY}
            onClick={confirmAction}
            text="Confirm"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmActionDialog;
