import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';

import { RootState } from '../../reducers';
import { User } from '../../models';

export const PrivateRoute = ({ component: Component, user, ...rest }:
    {component: any; user: User }): JSX.Element => (
  <Route
    {...rest}
    render={props => (
      user ? (
        <Component {...props} />
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location },
        }} />
      )
    )} />
);

export default connect((state: RootState) => ({user: state.user}))(PrivateRoute);
