import * as React from 'react';
import {
  Alignment, AnchorButton, Button, Callout, ContextMenu,
  Icon, Intent, Menu, MenuItem, Popover, PopoverInteractionKind, Dialog, Position,
} from '@blueprintjs/core';
import * as linkify from 'linkifyjs';
import Linkify from 'react-linkify'
import { Map } from 'immutable';

import { IconNames } from '@blueprintjs/icons';

import EditableText from './EditableText';
import DynamicStringSelect from './DynamicStringSelect';
import { COLUMN_TYPES, IColumn, ObjectType } from '../../types/model_helpers';
import { STATUS_OPTIONS } from '../../types/model_helpers';
import { statusIntent } from '../../utils/intent_helpers';

interface IProps {
  onColumnWidthChanged: (size: number, colIndex: number) => any;
  onRowAdded: (rowIndex: number) => any;
  onCellEdited: (value: string, colIndex: number, rowIndex: number) => any;
  onColumnSearch: (filter: string, value: string) => any;
  save?: () => any;
  onColumnTypeChanged?: (type: COLUMN_TYPES, colIndex: number) => any;
  onColumnValsEdited?: (values: string[], colIndex: number) => any;
  columns: IColumn[];
  rows: ObjectType[];
  isDarkTheme: boolean;
  queryParams: any;
  type: ListType;
  cellColors?: Map<string, string>;
}

interface IState {
  resizeData: any;
  helpToggled: boolean;
  helpMessage: string;
}

export enum ListType {
  CHECKLIST,
  CHECKLIST_TEMPLATE,
  TRAINING,
  TRAINING_TEMPLATE,
}

export class ItemList extends React.Component<IProps, IState> {

  static defaultProps = {
    save: () => {},
    cellColors: Map(),
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      resizeData: {
        curCol: null,
        curColWidth: null,
        pageX: null,
        col: null,
      },
      helpToggled: false,
      helpMessage: '',
    };
  }

  render(): JSX.Element {
    const { rows, columns, type, isDarkTheme } = this.props;
    const { helpToggled, helpMessage } = this.state;

    return (
      <div className="pt-card pt-elevation-2"
        style={{ margin: '0 auto 20px', display: 'inline-block', maxWidth: 'calc(100vw - 40px)', overflow: 'auto' }}>
        {
          type === ListType.TRAINING_TEMPLATE &&
          <Callout intent={Intent.PRIMARY} className="mbs">
            Changes in existing rows will only be reflected in existing training
            lists if &quot;Update Existing&quot; is selected when saving.
          </Callout>
        }
        <table className="pt-html-table pt-checklist pt-interactive resizable">
          <thead>
            <tr>
              {columns.map(this.renderHeader)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {columns.map(this.renderHeaderFilter)}
            </tr>
            { rows.map((row, idx) =>
              <tr key={`row-${idx}`} onContextMenu={ e => this.showContextMenu(e, idx) }>
                {
                  this.renderRow(
                    (row['actualRowIndex'] !== undefined ? Number(row['actualRowIndex']) : idx),
                    row, columns,
                  )
                }
              </tr>)}
          </tbody>
        </table>

        <Dialog
          className={`${isDarkTheme ? 'pt-dark' : 'pt-light'}`}
          title="Additional Details"
          isOpen={helpToggled}
          onClose={() => this.setState({ helpToggled: false })}
          style={{ paddingBottom: 0 }}>
          <div className="pt-dialog-body">
            {helpMessage}
          </div>
        </Dialog>
      </div>
    );
  }

  public componentDidMount(): void {
    document.addEventListener('mousemove', this.resizeMouseMove);
    document.addEventListener('mouseup', this.resizeMouseUp);
  }

  private renderHeader = (col: IColumn, idx: number): JSX.Element => {
    const { type } = this.props;
    const isTemplate = type === ListType.CHECKLIST_TEMPLATE || type === ListType.TRAINING_TEMPLATE;

    // Don't show "Help Text" header title if on checklist page
    if (!isTemplate && col.key === 'helpText')
      return <th key={`header-${idx}`} />

    return (<th key={`header-${idx}`}>
      {col.name}
      {
        isTemplate &&
        <button type="button"
          title="Change column type"
          onClick={ e => this.showHeaderContext(e, idx) }
          style={{ marginTop: '-4px' }}
          className="pt-button pt-minimal pt-small pt-icon-caret-down"></button>
      }
      <div className="resize-handle pt-icon-drag-handle-vertical"
        title="Resize column"
        onMouseDown={ e => this.resizeMouseDown(e, idx) }
      ></div>
    </th>)
  }

  private renderHeaderFilter = (col: IColumn, idx: number): JSX.Element => {
    const { onColumnSearch, queryParams, type } = this.props;
    const isTemplate = type === ListType.CHECKLIST_TEMPLATE || type === ListType.TRAINING_TEMPLATE;

    // Don't show "Help Text" search field
    if (!isTemplate && col.key === 'helpText')
      return <td key={`header-search-${idx}`} />

    return (<td key={`header-search-${idx}`} style={{ maxWidth: col.width, minWidth: col.width }}>
      <input className="pt-input pt-fill" type="text" dir="auto" defaultValue={queryParams[col.key]}
        onChange={e => onColumnSearch(col.key, e.target.value) } />
    </td>);
  }

  private renderRow = (rowIdx: number, row: ObjectType, columns: Array<IColumn>): JSX.Element[] => {
    const { onCellEdited, onColumnValsEdited, save, type, cellColors } = this.props;
    return columns.map((col, idx) => {
      // No value in cell
      if (row[col.key] === undefined)
        return (<td style={{ maxWidth: col.width, minWidth: col.width }}>--</td>);

      const value = `${row[col.key]}`;
      const isTemplateEditable = (type === ListType.CHECKLIST_TEMPLATE || type === ListType.TRAINING_TEMPLATE)
          && col.type === COLUMN_TYPES.NOT_EDITABLE;

      const backgroundColor = cellColors.get(`${rowIdx}-${idx}`);
      const isUpdatedByCol = col.key === 'updatedBy';
      const isHelpTextCol = col.key === 'helpText';

      // Show (?) if "Help Text" has any content, otherwise show nothing
      if (isHelpTextCol && (type === ListType.CHECKLIST || type === ListType.TRAINING)) {
        if (!value)
          return <td key={`row-${rowIdx}-col-${idx}`} style={{ backgroundColor }} />;
        return (
          <td key={`row-${rowIdx}-col-${idx}`} style={{ backgroundColor }}>
            <Icon icon={IconNames.HELP} intent={Intent.NONE}
              className="row-help-icon" title="Additional details"

              onClick={() => this.setState({ helpToggled: true, helpMessage: value })}/>
          </td>
        );
      }

      // Text cell
      if (!isUpdatedByCol && (col.type === COLUMN_TYPES.TEXT || isTemplateEditable)) {
        const linksInValue = linkify.find(value);
        return (
          <td key={`row-${rowIdx}-col-${idx}`}
            style={{ maxWidth: col.width, minWidth: col.width, backgroundColor }}
            className={isTemplateEditable ? 'template-not-editable' : ''}>
            <Popover
              interactionKind={PopoverInteractionKind.HOVER}
              autoFocus={false}
              enforceFocus={false}
              disabled={isTemplateEditable || linksInValue.length === 0}>
              <EditableText
                confirmOnEnterKey={true}
                multiline={true}
                maxLines={3}
                onChange={value => onCellEdited(value, idx, rowIdx)}
                onConfirm={() => save()}
                placeholder="--"
                value={value} />
              <div style={{ display: 'flex', flexFlow: 'column' }}>
                {
                  linksInValue.map((link, linkIdx) =>
                    <AnchorButton href={link.href}
                      icon={<Icon icon="link" title="Open link" />}
                      minimal={true}
                      key={`text-link-${linkIdx}`}
                      target='_blank'
                      style={{ textAlign: 'left' }}
                      alignText={Alignment.LEFT}
                      fill={true}
                      text={link.value.length > 50 ? `${link.value.substring(0, 50)}...` : link.value} />)
                }
              </div>
            </Popover>
          </td>
        );
      }

      // Status or Dropdown cell
      if (!isUpdatedByCol && (col.type === COLUMN_TYPES.STATUS || col.type === COLUMN_TYPES.DROPDOWN)) {
        let bgColor = '';
        if (col.type === COLUMN_TYPES.DROPDOWN) bgColor = '';
        else if (value === 'Pass' || value === 'Yes') bgColor = 'PassYes';
        else if (value === 'Skip' || value === 'N/A') bgColor = 'SkipNA';
        return (
          <td key={`row-${rowIdx}-col-${idx}`} style={{ maxWidth: col.width, minWidth: col.width, backgroundColor }}>
            <DynamicStringSelect
              inputProps={{value}}
              items={col.type === COLUMN_TYPES.STATUS ? STATUS_OPTIONS: col.values || []}
              canAdd={(type === ListType.CHECKLIST_TEMPLATE || type === ListType.TRAINING_TEMPLATE)
                && col.type === COLUMN_TYPES.DROPDOWN}
              handleAdd={value =>
                onColumnValsEdited(col.values ? [...col.values, value] : [value], idx)
              }
              isItemActive={item => value === item}
              onItemSelect={item => { onCellEdited(item, idx, rowIdx); return save(); }}
            >
              <Button className={`${bgColor}`} intent={statusIntent(value)}
                rightIcon={<Icon icon="caret-down" title="Select value" />}>
                {value}
              </Button>
            </DynamicStringSelect>
          </td>
        );
      }

      // if the entire entry is a link just show an icon to save space
      if(linkify.test(value)) {
        const linksInValue = linkify.find(value);
        const link = linksInValue[0].href;
        return (
          <td 
            key={`row-${rowIdx}-col-${idx}`} 
            style={{ maxWidth: col.width, minWidth: col.width, backgroundColor, textAlign: "center" }}>
            <Popover 
              content={<Menu>{link}</Menu>} 
              interactionKind={PopoverInteractionKind.HOVER}
              autoFocus={false}
              enforceFocus={false}
              position={Position.BOTTOM}
            >
                <a href={link} target="_blank" rel="noopener noreferrer"><Icon icon="link" title="Open link" /></a>
            </Popover>
          </td>
        );
      }

      // Not Editable Text
      return (
        <td key={`row-${rowIdx}-col-${idx}`} style={{ maxWidth: col.width, minWidth: col.width, backgroundColor }}>
          { <Linkify>{value}</Linkify> || '--' }
        </td>
      );
    });
  };

  private columnTypeChanged = (type: COLUMN_TYPES, colIndex: number): any => {
    const { onColumnTypeChanged, save } = this.props;
    save();
    return onColumnTypeChanged(type, colIndex);
  };

  /**
   * Shows the context menu to change the type of data in each column
   *
   * @param {React.MouseEvent<HTMLButtonEvent>} e The mouse click event
   * @param {number} colIdx The column index that was clicked
   */
  private showHeaderContext = (e: React.MouseEvent<HTMLButtonElement>, colIdx: number): void => {
    const { columns, isDarkTheme } = this.props;
    e.preventDefault();

    const menu = React.createElement(
      Menu,
      {},
      React.createElement(MenuItem, { text: 'Input Types', disabled: true }),
      React.createElement(MenuItem, {
        text: 'Text Input',
        onClick: () => this.columnTypeChanged(COLUMN_TYPES.TEXT, colIdx),
        active: columns[colIdx].type === COLUMN_TYPES.TEXT,
      }),
      React.createElement(MenuItem, {
        text: 'Dropdown',
        onClick: () => this.columnTypeChanged(COLUMN_TYPES.DROPDOWN, colIdx),
        active: columns[colIdx].type === COLUMN_TYPES.DROPDOWN,
      }),
      React.createElement(MenuItem, {
        text: 'Status',
        onClick: () => this.columnTypeChanged(COLUMN_TYPES.STATUS, colIdx),
        active: columns[colIdx].type === COLUMN_TYPES.STATUS,
      }),
      React.createElement(MenuItem, {
        text: 'Not Editable',
        onClick: () => this.columnTypeChanged(COLUMN_TYPES.NOT_EDITABLE, colIdx),
        active: columns[colIdx].type === COLUMN_TYPES.NOT_EDITABLE,
      }),
    );

    const element = e.target as HTMLButtonElement;
    element.style.opacity = '1';
    ContextMenu.show(menu, { left: e.clientX, top: e.clientY }, () => {
      element.style.opacity = null;
    }, isDarkTheme);
  };

  /**
   * Shows the context menu when right-clicking on the table to add a new row to the table
   *
   * @param {React.MouseEvent<HTMLTableRowElement>} e The mouse click event
   * @param {number} rowIdx The row index that was clicked
   */
  private showContextMenu = (e: React.MouseEvent<HTMLTableRowElement>, rowIdx: number): void => {
    const { isDarkTheme, onRowAdded, type, rows } = this.props;
    if (type === ListType.TRAINING) return;

    e.preventDefault();

    let menu;

    if (type === ListType.TRAINING_TEMPLATE) {
      menu = React.createElement(
        Menu,
        {},
        React.createElement(MenuItem, {
          onClick: () => onRowAdded(rows.length),
          text: 'Insert Row to End',
        }),
      )
    }
    else {
      menu = React.createElement(
        Menu,
        {},
        React.createElement(MenuItem, {
          onClick: () => onRowAdded(rowIdx),
          text: 'Insert Row Above',
        }),
        React.createElement(MenuItem, {
          onClick: () => onRowAdded(rowIdx + 1),
          text: 'Insert Row Below',
        }),
      );
    }
    ContextMenu.show(menu, { left: e.clientX, top: e.clientY }, () => {}, isDarkTheme);
  };

  /**
   * Called when the resize icon is clicked in the table row header
   */
  private resizeMouseDown = (e: React.MouseEvent<HTMLDivElement>, colIdx: number): void => {
    e.preventDefault();

    const cur = (e.target as HTMLDivElement).parentElement;
    this.setState({ resizeData: {
      curCol: cur,
      curColWidth: cur.offsetWidth,
      pageX: e.pageX,
      col: colIdx,
    } });
  };

  /**
   * Called when resizing and the mouse moves
   */
  private resizeMouseMove = (e: MouseEvent): void => {
    const { resizeData: { curCol, pageX, curColWidth, col } } = this.state;

    if (curCol) {
      e.preventDefault();
      const diffX = e.pageX - pageX;
      curCol.style.maxWidth = `${(curColWidth + diffX)}px`;
      curCol.style.minWidth = `${(curColWidth + diffX)}px`;
      curCol.style.boxShadow = '-2px 0 0 rgba(0,0,0,0.25) inset';
      curCol.style.backgroundColor = 'rgba(0,0,0,0.05)';

      const cells = document.querySelectorAll(`.pt-checklist td:nth-child(${col+1})`);
      for (let i = 0; i < cells.length; i++) {
        const item = cells.item(i) as HTMLTableCellElement;
        item.style.maxWidth = `${(curColWidth + diffX)}px`;
        item.style.minWidth = `${(curColWidth + diffX)}px`;
        item.style.backgroundColor = 'rgba(0,0,0,0.05)';
        item.style.boxShadow = '-2px 0 0 rgba(0,0,0,0.25) inset';
        if (i === 0)
          item.style.boxShadow = '-2px 0 0 rgba(0,0,0,0.25) inset, inset 0 1px 0 0 rgba(16, 22, 26, 0.15)';

      }
    }
  };

  /**
   * Called when resizing and the mouse button is lifted
   */
  private resizeMouseUp = (e: MouseEvent): void => {
    const { resizeData: { curCol, pageX, curColWidth, col } } = this.state;
    const { onColumnWidthChanged, save } = this.props;

    if (curCol) {
      const diffX = e.pageX - pageX;
      onColumnWidthChanged(curColWidth + diffX, col);
      save();

      curCol.style.boxShadow = null;
      curCol.style.backgroundColor = null;
      const cells = document.querySelectorAll(`.pt-checklist td:nth-child(${col+1})`);
      for (let i = 0; i < cells.length; i++) {
        const item = cells.item(i) as HTMLTableCellElement;
        item.style.boxShadow = null;
        item.style.backgroundColor = null;
      }

      this.setState({
        resizeData: {
          curCol: null,
          curColWidth: null,
          pageX: null,
          col: null,
        },
      });
    }
  };
}