import { Record } from 'immutable';

export interface IWhatsNew {
  userId?: string;
  lastVersion: string;
  enabled: boolean;
}

export class WhatsNew extends Record<IWhatsNew>({
  userId: '',
  lastVersion: '',
  enabled: true,
}, 'WhatsNew') implements IWhatsNew {
  public userId?: string;
  public lastVersion: string;
  public enabled: boolean;
  constructor(props: IWhatsNew) {
    super(props);
  }
}