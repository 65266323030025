import { Record } from 'immutable';

export interface ITheme {
  isDark: boolean;
}

export class Theme extends Record<ITheme>({
  isDark: false,
}, 'Theme') implements ITheme {
  public isDark: boolean;
  constructor(props?: ITheme) {
    super(props);
  }
}
