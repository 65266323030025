import { ThemeActionTypes, ThemeActions } from '../actions/theme';
import { Theme } from '../models/Theme';

export type ThemeState = Theme;
const initialState: ThemeState = new Theme();

export default function themeReducer(
  state: ThemeState = initialState,
  action: ThemeActions,
): ThemeState {
  switch (action.type) {
    case ThemeActionTypes.LOAD_DARK:
    case ThemeActionTypes.SET_DARK: {
      const { isDark } = action;
      return new Theme({isDark});
    }
    default:
      return state;
  }
}
