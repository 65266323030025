import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Prompt } from 'react-router-dom';
import * as moment from 'moment';
import { Map } from 'immutable';
import { History, Location } from 'history';

import { IRegion } from '@blueprintjs/table';
import { IconNames } from '@blueprintjs/icons';
import { Button, Icon, Intent } from '@blueprintjs/core';

import { ITemplate, Template, Theme, User, IFieldChange } from '../models';
import * as templateActions from '../actions/template';
import * as themeActions from '../actions/theme';
import { ITemplateFetchAction } from '../actions/template';
import { IErrorAction } from '../actions/error';

import CenterContainer from './shared/CenterContainer';
import Navbar from './shared/Navbar';
import ConfirmActionDialog from './shared/ConfirmActionDialog';
import ErrorDialog from './shared/ErrorDialog';
import EditableText from './shared/EditableText';
import { ItemList, ListType } from './shared/ItemList';
import YesNoActionDialog from './shared/YesNoActionDialog';

import { COLUMN_TYPES } from '../types/model_helpers';
import { filterRows, updateColumnFilters } from '../utils/table_helpers';
import { RootState } from '../reducers';

type PathParamsType = {
  id: string;
};
interface IProps extends RouteComponentProps<PathParamsType> {
  template?: Template;
  history: History;
  location: Location;
  fetch: (id: number) => Promise<ITemplateFetchAction | IErrorAction>;
  id: number;
  user: User;
  theme: Theme;
  loadIsDarkAction: () => any;
  cellEdited(value: string, colIndex: number, rowIndex: number, template: ITemplate, user: User): any;
  nameEdited(value: string, template: ITemplate): any;
  columnWidthEdited(value: number, colIndex: number, template: ITemplate): any;
  columnValuesEdited(values: string[], colIndex: number, template: ITemplate): any;
  columnTypeEdited(type: COLUMN_TYPES, colIndex: number, template: ITemplate): any;
  rowAdded(index: number, template: ITemplate): any;
  update(template: ITemplate): any;
  create(template: ITemplate): any;
  destroy(template: ITemplate): any;
}

interface IState {
  loading: boolean;
  dirty: boolean;
  selection: IRegion[];
  toggledDelete: boolean;
  toggledOverwrite: boolean;
  showSaveSuccess: boolean;
  err?: string;
  editingFilter: boolean;
  changes: Map<string, string>;
}

export class TemplatePage extends React.Component<IProps, IState, never> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      dirty: false,
      selection: [],
      toggledDelete: false,
      toggledOverwrite: false,
      showSaveSuccess: false,
      err: null,
      editingFilter: false,
      changes: Map(),
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  public render(): JSX.Element {
    const {
      template, nameEdited, columnWidthEdited,
      rowAdded, history, location: {query}, user,
      theme, cellEdited, columnTypeEdited,
      columnValuesEdited,
    } = this.props;
    const {
      loading,
      toggledDelete,
      showSaveSuccess,
      changes,
      toggledOverwrite,
    } = this.state;

    if ( loading )
      return <CenterContainer centered loading theme={theme.isDark}><h2>Loading...</h2></CenterContainer>;
    if ( ! template )
      return <CenterContainer centered><h2>Not found!</h2></CenterContainer>;

    const { name, rows, columns, dirty, id } = template;

    // const filteredRows = columnFilters(rows, query);
    const filteredRows = filterRows(rows, query);

    return (
      <div className={`pt-app ${theme.isDark ? 'pt-dark' : 'pt-light'}`}>
        <Prompt
          when={dirty}
          message={location =>
            location.pathname.startsWith(`/templates/${id}`) ? true : // returning true will allow the transition
              'Your changes have not been saved. Do you want to leave this page?'
          }
        />
        <ConfirmActionDialog
          theme={theme}
          toggled={toggledDelete}
          onClose={this.toggleDelete}
          entity={template}
          action={this.deleteChecklist}
          message={'Are you sure you want to delete?'} />
        <YesNoActionDialog
          theme={theme} toggled={toggledOverwrite}
          onClose={this.toggleOverwrite}
          onYes={() => this.updateTemplate(true).then(this.toggleOverwrite)}
          onNo={() => this.updateTemplate(false).then(this.toggleOverwrite)}
          hasCancel={true}
          noText='No (save without updating)'
          noIntent={Intent.PRIMARY}
          yesText='Yes (update existing)'
          yesIntent={Intent.DANGER}
          message={
            <span>
              Do you want to update existing training lists with the changed fields?<br /><br />
              <i>Note: New rows will be added regardless.</i>
            </span>
          } />
        <ErrorDialog />
        <Navbar
          isCheckup={!template.isTraining}
          showTitle={false}
          navbarLeft={
            <React.Fragment>
              <h3 className="nav-title" style={{ textAlign: 'left' }}>
                <EditableText defaultValue={name}
                  onConfirm={val => nameEdited(val, template)} />
              </h3>
              <span className="pt-tag mls">Template</span>
            </React.Fragment>
          }
          navbarRight={
            <React.Fragment>
              <div className="last-edit-time">
                { (!dirty && showSaveSuccess) ? 'All changes saved' :
                  ('Last save was ' +
                    moment(template.updatedAt).calendar(null, {
                      sameDay: '[today at] h:mm A',
                      lastDay: '[yesterday at] h:mm A',
                      lastWeek: '[last] dddd',
                      sameElse: '[on] MM/DD/YYYY',
                    }))
                }
              </div>
              <Button
                title="Save changes"
                minimal={true}
                icon={<Icon icon={IconNames.FLOPPY_DISK} title="Save Template" />}
                intent={dirty ? Intent.SUCCESS : Intent.NONE}
                disabled={!dirty}
                onClick={this.performSave}
              />
              <Button
                title="Delete template"
                minimal={true}
                icon={<Icon icon={IconNames.TRASH} title="Delete Template" />}
                intent={Intent.DANGER}
                className="mrs"
                onClick={this.toggleDelete}
              />
            </React.Fragment>
          }
        />
        {/* spacer under nav bar: should be $pt-navbar-height
        http://blueprintjs.com/docs/v2/#core/components/navbar.fixed-to-viewport-top
        */}
        <div style={{marginTop: '4.2rem', textAlign: 'center'}}>
          <ItemList
            type={template.isTraining ? ListType.TRAINING_TEMPLATE : ListType.CHECKLIST_TEMPLATE}
            onColumnWidthChanged={(size, colIndex) => columnWidthEdited(size, colIndex, template)}
            onRowAdded={(rowIndex) => rowAdded(rowIndex, template)}
            onCellEdited={(value, colIndex, rowIndex) => {
              const res = cellEdited(value, colIndex, rowIndex, template, user);
              if (res.type !== 'NOOP') this.forceUpdateField(rowIndex, colIndex, value);
            }}
            onColumnTypeChanged={(type, colIndex) => columnTypeEdited(type, colIndex, template)}
            onColumnValsEdited={(values, colIndex) => columnValuesEdited(values, colIndex, template)}
            columns={columns}
            rows={filteredRows}
            isDarkTheme={theme.isDark}
            onColumnSearch={(filter, value) => updateColumnFilters(query, history, filter, value)}
            queryParams={query}
            cellColors={changes.map(() => theme.isDark ? 'rgba(255, 255, 0, 0.05)' : 'rgba(255, 255, 0, 0.3)').toMap()}
          />
        </div>
      </div>
    );
  }

  public handleKeyDown = (event: KeyboardEvent): void => {
    const charCode = String.fromCharCode(event.which).toLowerCase();

    // Ctrl-S
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      if (this.props.template.dirty)
        this.performSave();

      event.preventDefault();
    }
  }

  public componentWillReceiveProps(nextProps: IProps): void {
    const { id: oldId, fetch } = this.props;
    const { id: newId } = nextProps;

    if (oldId !== newId) {
      this.setState({loading: true}, () => {
        fetch(newId)
          .then(() => this.setState({loading: false}))
          .catch(() => this.setState({loading: false}));
      });
    }
  }

  public componentDidMount(): void {
    const {
      template,
      fetch,
      id,
      loadIsDarkAction,
    } = this.props;
    loadIsDarkAction();
    if (!template) {
      this.setState({loading: true}, () => {
        fetch(id)
          .then((data: ITemplateFetchAction) => {
            if (data.template)
              document.title = `Template - ${data.template.name}`;
            this.setState({loading: false});
          })
          .catch(() => this.setState({loading: false}));
      });
    }
    else
      document.title = `Template – ${template.name}`;

    document.addEventListener('keydown', this.handleKeyDown);
  }

  private performSave = (): void => {
    const { template } = this.props;
    const { changes } = this.state;

    if (template.isTraining && changes.size > 0) this.toggleOverwrite();
    else this.updateTemplate(false);
  }
  private toggleOverwrite = (): void => this.setState({toggledOverwrite: !this.state.toggledOverwrite});
  private toggleDelete = (): void => this.setState({toggledDelete: !this.state.toggledDelete});
  private deleteChecklist = (template: ITemplate): void => {
    const { history, destroy } = this.props;
    return destroy(template).then((data: templateActions.ITemplateDestroyAction | IErrorAction) => {
      switch (data.type) {
        case templateActions.TemplateActionTypes.DELETE_SUCCESS:
          history.push('/templates', { deleteSuccess: template.name });
          break;
      }
    });
  }

  private updateTemplate = (pushChanges: boolean): Promise<void> => {
    const { update, template } = this.props;
    const { changes } = this.state;

    let changeList: IFieldChange[] = [];
    if (pushChanges) {
      changeList = changes.reduce((acc: IFieldChange[], value, key) => {
        const splitIndex = key.indexOf('-');
        const row = Number(key.substring(0, splitIndex));
        const col = Number(key.substring(splitIndex + 1));

        acc.push({ rowIndex: row, colIndex: col, newValue: value });
        return acc;
      }, []);
    }

    const changedTemplate = new Template({
      ...template.toJS(),
      changes: changeList,
    });

    return update(changedTemplate)
      .then(() => this.setState({showSaveSuccess: true}))
      .then(() => {
        setTimeout(() => {
          this.setState({showSaveSuccess: false});
        }, 5000);
      })
      .catch((err: string) => this.setState({err}))
      .then(() => this.setState({ changes: Map() }))
  }

  private forceUpdateField = (rowIndex: number, colIndex: number, newValue: string): void => {
    const { changes } = this.state;
    const newChanges = changes.set(`${rowIndex}-${colIndex}`, newValue);
    this.setState({ changes: newChanges });
  };
}

function mapStateToProps(state: RootState, ownProps: IProps): object {
  const { templates, user, theme } = state;
  const {match: {params: {id}}} = ownProps;

  return {
    theme,
    id: Number(id),
    template: templates.get(Number(id)),
    user,
  };
}

export default withRouter(connect(mapStateToProps, {
  ...templateActions,
  loadIsDarkAction: themeActions.loadIsDark,
})(TemplatePage));
