import { Record } from 'immutable';

import { IColumn, ObjectType } from '../types/model_helpers';

export interface IFieldChange {
  rowIndex: number;
  colIndex: number;
  newValue: string;
}

export interface ITemplate {
  id?: number;
  name: string;
  description: string;
  isTraining: boolean;
  columns: IColumn[];
  rows: ObjectType[];
  tags: string[];
  createdAt?: Date;
  updatedAt?: Date;
  updatedBy?: string;
  version: number;
  dirty?: boolean;
  changes?: IFieldChange[];
}

export class Template extends Record<ITemplate>({
  id: undefined,
  name: '',
  description: '',
  isTraining: false,
  columns: [],
  rows: [],
  tags: [],
  dirty: false,
  createdAt: null,
  updatedAt: null,
  updatedBy: null,
  version: 0,
  changes: [],
}, 'Template') implements ITemplate {
  public id?: number;
  public name: string;
  public description: string;
  public isTraining: boolean;
  public columns: IColumn[];
  public rows: ObjectType[];
  public tags: string[];
  public dirty?: boolean;
  public createdAt?: Date;
  public updatedAt?: Date;
  public updatedBy?: string;
  public version: number;
  public changes?: IFieldChange[];
  constructor(props: ITemplate) {
    super(props);
  }
}
