import { History } from 'history';

import { ObjectType } from '../types/model_helpers';

/**
 *  @param item typically a row that we want to filter based on its cell contents
 *  @param query an object that contains key/value pairs that correspond to column names/filter text
 *  @returns a boolean if all of the column filters match the corresponding values
 */
export const matchesFilter = (item: ObjectType, query: ObjectType): boolean =>
  Object.keys(query).every((key: string) => {
    if(key === 'sort' || key === 'order')
      return true;


    const matchVal = String(item[key]).toLowerCase();

    // Match any in array
    if (Array.isArray(query[key]))
      return query[key].some((qry: string) => qry.toLowerCase() === matchVal);

    // Match key/value pair
    return matchVal.includes(query[key].toLowerCase());
  });

/**
 *  @param rows typically an array of rows that we want to filter based on its cell contents
 *  @param query an object that contains key/value pairs that correspond to column names/filter text
 *  @returns an array of rows that match the queries for appropriate columns
 */
export const filterRows = (rows: ObjectType[], query: ObjectType): ObjectType[] =>
  rows.reduce<ObjectType[]>((acc: ObjectType[], row: ObjectType, i: number) => {
    if (matchesFilter(row, query)) acc.push({...row, actualRowIndex: i});
    return acc;
  }, []);

/**
 * A helper function that returns an object with only the properties that have values.
 * @param filters: an object
 * @returns an object with no null/empty/undefined properties
 */
const cleanQueryFilters = (filters: ObjectType): ObjectType =>
  Object.keys(filters).reduce((acc: ObjectType, key: string) => {
    if (filters[key]) acc[key] = filters[key];
    return acc;
  }, {});

/**
 *
 * @param locationQuery query property from props.location
 * @param history history object from props
 * @param filter the column name that a filter was applied to
 * @param value the value of the filter
 * Note: replaces the current url with search filters appended
 */
export const updateColumnFilters = (locationQuery: object, history: History, filter: string, value: any): void =>
  history.replace({query: cleanQueryFilters({...locationQuery, [filter]: value})});

/**
 * @param locationQuery query property from props.location
 * @param history history object from props
 * @param sort what column is being sorted
 * @param name the name of the column being actively sorted
 * @param order what direction the sort is displaying
 * @param asc the order of sort direction, displays nothing if false
 */
export const updateSortOrder = (
  locationQuery: object, history: History, sort: string,
  name: string, order: string, asc: boolean,
): void =>
  history.replace({query: cleanQueryFilters({...locationQuery, [sort]: name, [order]: asc})});
