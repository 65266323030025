import { EditableText } from '@blueprintjs/core';
import { safeInvoke } from '@blueprintjs/core/src/common/utils';
import { IEditableTextProps, IEditableTextState } from '@blueprintjs/core/src/components/editable-text/editableText';

export default class EditableText2 extends EditableText {
  public componentDidUpdate(_: IEditableTextProps, prevState: IEditableTextState): void {
    if (this.state.isEditing && !prevState.isEditing)
      safeInvoke(this.props.onEdit, this.state.value);

    // BUG: https://github.com/palantir/blueprint/issues/2021
    // this.updateInputDimensions();
  }
}
