import { Record } from 'immutable';

export interface IUser {
  groups?: string[];
  profile?: any;
}

export class User extends Record<IUser>({
  groups: [],
  profile: {},
}, 'User') implements IUser {
  public groups: string[];
  public profile: any;
  constructor(props: IUser) {
    super(props);
  }
}