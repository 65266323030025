import { Action } from 'redux';
import { AxiosError } from 'axios';

import { ThunkDispatch } from '../typings/thunk';
import { ErrorState } from '../reducers/error';

export enum ErrorActionTypes {
  ERROR = 'ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR',
}

export interface IErrorAction extends Action<string> {
  type: ErrorActionTypes.ERROR;
  err: AxiosError;
}

export interface IClearErrorAction extends Action<string> {
  type: ErrorActionTypes.CLEAR_ERROR;
}

export type ErrorActions = IErrorAction | IClearErrorAction;

export function clearError() {
  return (dispatch: ThunkDispatch<ErrorState, null, IClearErrorAction>) => {
    dispatch({type: ErrorActionTypes.CLEAR_ERROR});
  }
}