import { ErrorActionTypes, ErrorActions } from '../actions/error';

export type ErrorState = Error;
const initialState: ErrorState = null;

export default function errorReducer(state: ErrorState = initialState, action: ErrorActions): ErrorState {
  switch (action.type) {
    case ErrorActionTypes.ERROR:
      const { err } = action;
      console.error(err); // eslint-disable-line
      return err;
    case ErrorActionTypes.CLEAR_ERROR:
      return null;
    default:
      return state;
  }
}
