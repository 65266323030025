import { List } from 'immutable';

import { IFavorite } from '../models/Favorite';
import { FavoriteActionTypes, FavoriteActions } from '../actions/favorite';

export type FavoriteState = List<IFavorite>;
const initialState: FavoriteState = List<IFavorite>();

export default function favoriteReducer(
  state: FavoriteState = initialState,
  action: FavoriteActions,
): FavoriteState {
  switch(action.type) {
    case FavoriteActionTypes.FETCH_SUCCESS:
      const { favorites } = action;
      return List(favorites);
    case FavoriteActionTypes.CREATE_SUCCESS: {
      const { favorite } = action;
      return state.push(favorite);
    }
    case FavoriteActionTypes.DELETE_SUCCESS: {
      const { favorite } = action;
      return state.remove(favorite.id);
    }
    default:
      return state;
  }
}