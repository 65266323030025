import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import { Location, History } from 'history';

import { IconNames } from '@blueprintjs/icons';
import { Spinner, Button, Intent } from '@blueprintjs/core';

import { ITemplate, Template, Theme } from '../models';
import * as templateActions from '../actions/template';
import * as themeActions from '../actions/theme';
import { ITemplateFetchAllAction } from '../actions/template';
import { IErrorAction } from '../actions/error';
import { AppToaster } from '../utils/toaster';

import CenterContainer from './shared/CenterContainer';
import Navbar from './shared/Navbar';
import { uploadCSVFn, UploadTemplateDialog } from './UploadTemplateDialog';
import { RootState } from '../reducers';

interface IProps {
  templates?: Map<number, Template>;
  fetchAll: () => Promise<ITemplateFetchAllAction | IErrorAction>;
  create: (template: ITemplate) => Promise<ITemplate>;
  uploadCSV: uploadCSVFn;
  location: Location;
  history: History;
  theme: Theme;
  loadIsDarkAction: () => any;
}

interface IState {
  loading: boolean;
  dialogToggled: boolean;
  uploadDialogToggled: boolean;
  selected: ITemplate;
  templateSubGroup: Map<number, Template>;
  isCheckup: boolean;
}

export class TemplatesPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      dialogToggled: false,
      uploadDialogToggled: false,
      selected: undefined,
      templateSubGroup: Map(),
      isCheckup: true,
    };
  }

  public render(): JSX.Element {
    const {
      uploadCSV,
      theme,
    } = this.props;
    const {
      loading,
      uploadDialogToggled,
      templateSubGroup,
      isCheckup,
    } = this.state;

    const hasTemplates = templateSubGroup.size > 0;

    return (
      <div className={`pt-app ${theme.isDark ? 'pt-dark' : 'pt-light'}`}>
        <Navbar
          isCheckup={isCheckup}
          navbarCenter={
            <div className="pt-navbar">
              <div className="pt-navbar-group pt-align-left">
                <Link
                  className={
                    'pt-button pt-minimal pt-large pt-icon-clipboard is-nav-link ' +
                    (isCheckup ? 'pt-icon-clipboard' : 'pt-icon-graph')
                  }
                  to={isCheckup ? '/lists' : '/training/lists'}>
                  {isCheckup ? 'Checklists' : 'Training'}
                </Link>
                <Link
                  className="pt-button pt-minimal pt-large pt-icon-document pt-active is-nav-link"
                  to={isCheckup ? '/templates/active' : '/training/templates'}>
                  Templates
                </Link>
              </div>
            </div>
          }
          navbarRight={
            <React.Fragment>
              <Button
                title="Upload template"
                minimal={true}
                className="mrs"
                icon={IconNames.CIRCLE_ARROW_UP}
                text="Upload"
                intent={Intent.PRIMARY}
                onClick={this.toggleUploadDialog}
              />
            </React.Fragment> } />
        {/* spacer under nav bar: should be $pt-navbar-height
        http://blueprintjs.com/docs/v2/#core/components/navbar.fixed-to-viewport-top
        */}
        <div style={{width: '100%', height: '3.25rem'}}/>
        <CenterContainer>
          <UploadTemplateDialog
            isCheckup={isCheckup}
            theme={theme}
            toggled={uploadDialogToggled}
            toggle={this.toggleUploadDialog}
            uploadCSV={uploadCSV} />

          {
            !loading && !hasTemplates &&
            <div className="pt-non-ideal-state">
              <div className="pt-non-ideal-state-visual pt-non-ideal-state-icon">
                <span className="pt-icon pt-icon-folder-open"></span>
              </div>
              <h4 className="pt-non-ideal-state-title">There are no existing templates</h4>
              <div className="pt-non-ideal-state-description">
                Upload a new template to begin
              </div>
            </div>
          }

          { !loading && hasTemplates && (<table className="pt-html-table full-width">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              { templateSubGroup.valueSeq()
                .sort((first, second) => first.name.localeCompare(second.name))
                .map(item =>
                  <tr key={item.id}>
                    <td>
                      <Link to={`/templates/${item.id}`}>
                        { item.name || `Template ${item.id}` }
                      </Link>
                    </td>
                    <td>{item.tags.map(tag => <span key={tag} className="pt-tag mrs">{tag}</span>)}</td>
                    <td>
                      <Link to={`/templates/${item.id}`}>
                        <Button minimal={true} type="button" className="pt-button pt-icon-edit"></Button>
                      </Link>
                    </td>
                  </tr>)
              }
            </tbody>
          </table>)}
          { loading &&
            <CenterContainer centered loading theme={theme.isDark}>
              <div style={{ height: 25 }} /><Spinner large />
            </CenterContainer>
          }
        </CenterContainer>
        <footer>
          {VERSION /* eslint-disable-line */} &copy; {new Date().getFullYear()} VoltServer Inc.
        </footer>
      </div>
    );
  }

  public componentDidMount(): void {
    const {
      fetchAll,
      loadIsDarkAction,
      location,
      history,
    } = this.props;
    loadIsDarkAction();
    this.setState({loading: true}, () => {
      fetchAll()
        .then(() => this.setState({loading: false}))
        .catch(() => this.setState({loading: false}));
    });

    if (location.state && location.state.deleteSuccess) {
      AppToaster.show({
        message: `Successfully deleted template "${location.state.deleteSuccess}"`,
        intent: Intent.SUCCESS,
      });
      history.replace('/templates', {});
    }
  }

  public componentWillReceiveProps(nextProps: IProps): void {
    const { location: {pathname}, templates } = nextProps;
    switch (pathname) {
      case '/training/templates':
        this.setState({
          templateSubGroup: templates.filter(template => template.isTraining),
          isCheckup: false,
        });
        document.title = 'TrainUp – Templates'
        break;
      default:
        this.setState({
          templateSubGroup: templates.filter(template => !template.isTraining),
          isCheckup: true,
        });
        document.title = 'Checkup – Templates'
        break;
    }
  }

  private toggleUploadDialog = (): void => this.setState({ uploadDialogToggled: !this.state.uploadDialogToggled });
}

function mapStateToProps(state: RootState): object {
  const { templates, theme } = state;
  return {
    templates,
    theme,
  };
}

export default connect(mapStateToProps, {
  ...templateActions,
  loadIsDarkAction: themeActions.loadIsDark,
})(TemplatesPage);
